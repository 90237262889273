import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const PartFetchingComponent = ({ selectedModel, threadId, language }) => {
  const [parts, setParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [showRawJson, setShowRawJson] = useState(false);
  const hasFetchedRef = useRef(false);

  const fetchParts = useCallback(async () => {
    if (!selectedModel || !threadId || hasFetchedRef.current) return;

    setIsLoading(true);
    setError(null);
    setProgress(0);
    hasFetchedRef.current = true;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/parts-list`,
        {
          selected_model: selectedModel,
          size: selectedModel.selectedSize,
          thread_id: threadId,
          language_key: language,
        },
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const { job_id } = response.data;

      let jobComplete = false;
      while (!jobComplete) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sheldon/parts-list/${job_id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (statusResponse.status === 202) {
          setProgress(statusResponse.data.progress);
        }
        if (statusResponse.status === 200) {
          jobComplete = true;

          const fetchedParts = Array.isArray(statusResponse.data.parts)
            ? statusResponse.data.parts
            : [];
          setParts(fetchedParts);
        }
      }
    } catch (error) {
      console.error("Error fetching parts:", error);
      setError("An error occurred while fetching the parts. Please try again.");
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  }, [selectedModel, threadId]);

  useEffect(() => {
    fetchParts();
  }, [fetchParts]);

  const toggleCategoryExpansion = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const renderSpecifications = (specs, depth = 0) => {
    if (typeof specs === "string") return <span>{specs}</span>;
    if (Array.isArray(specs)) return <span>{specs.join(", ")}</span>;
    if (typeof specs !== "object" || specs === null)
      return <span>{String(specs)}</span>;

    return (
      <ul className={`list-disc ${depth === 0 ? "pl-5" : "pl-4"}`}>
        {Object.entries(specs).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>{" "}
            {typeof value === "object" && value !== null ? (
              renderSpecifications(value, depth + 1)
            ) : (
              <span>
                {Array.isArray(value) ? value.join(", ") : String(value)}
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderFormattedPartsList = () => {
    if (parts.length === 0) {
      return <p>No parts information available.</p>;
    }

    const groupedParts = parts.reduce((acc, part) => {
      if (!acc[part.category]) {
        acc[part.category] = [];
      }
      acc[part.category].push(part);
      return acc;
    }, {});

    return (
      <div className="space-y-4">
        {Object.entries(groupedParts).map(([category, categoryParts]) => (
          <div key={category} className="bg-white p-4 rounded-lg shadow">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleCategoryExpansion(category)}
            >
              <h3 className="font-bold text-xl">{category}</h3>
              <FontAwesomeIcon
                icon={
                  expandedCategories[category] ? faChevronUp : faChevronDown
                }
              />
            </div>
            {expandedCategories[category] && (
              <div className="mt-4 space-y-4">
                {categoryParts.map((part, index) => (
                  <div key={index} className="bg-gray-50 p-3 rounded">
                    <h4 className="font-semibold text-lg">{part.name}</h4>
                    <div className="mt-2">
                      <p>
                        <strong>Brand:</strong> {part.brand}
                      </p>
                      <p>
                        <strong>Model:</strong> {part.model}
                      </p>
                      <div>
                        <strong>Specifications:</strong>
                        {renderSpecifications(part.specifications)}
                      </div>
                      {part.description && (
                        <p>
                          <strong>Description:</strong> {part.description}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderRawJson = () => {
    return (
      <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
        {JSON.stringify(parts, null, 2)}
      </pre>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <p>Fetching parts information...</p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-4">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="mt-2">{progress}% complete</p>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Bike Parts</h2>
      <div className="mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          onClick={() => setShowRawJson(!showRawJson)}
        >
          {showRawJson ? "Show Formatted List" : "Show Raw JSON"}
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            hasFetchedRef.current = false;
            fetchParts();
          }}
        >
          Refresh Parts List
        </button>
      </div>
      {showRawJson ? renderRawJson() : renderFormattedPartsList()}
    </div>
  );
};

export default PartFetchingComponent;
