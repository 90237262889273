import React, { useState } from "react";
import CustomSelect from "./customSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const ValuationForm = ({ settings, initialData, renderButton }) => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState(initialData || {});
  const [transitioning, setTransitioning] = useState(false);

  const questions = [
    {
      question: "What's the condition of the bike?",
      options: ["New", "Excellent", "Good", "Fair", "Poor", "For Parts"],
      key: "condition",
    },
    {
      question: "How many miles has your bike been ridden?",
      options: [
        "0-1,000 miles",
        "1,001-5,000 miles",
        "5,001-10,000 miles",
        "10,001+ miles",
      ],
      key: "estimatedMiles",
    },
    {
      question: "Where is your bike located?",
      key: "location",
      type: "location",
    },
    {
      question: "Any modifications to your bike?",
      key: "modifications",
      freeform: true,
    },
    {
      question: "Any additional notes about your bike?",
      key: "additionalNotes",
      type: "textarea",
    },
  ];

  const handleAnswer = (answer, key) => {
    setAnswers({ ...answers, [key]: answer });
    if (
      !questions[step].freeform &&
      questions[step].type !== "location" &&
      questions[step].type !== "textarea"
    ) {
      moveToNextQuestion();
    }
  };

  const moveToNextQuestion = () => {
    if (step < questions.length - 1) {
      setTransitioning(true);
      setTimeout(() => {
        setStep(step + 1);
        setTransitioning(false);
      }, 300);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setStep(step - 1);
        setTransitioning(false);
      }, 300);
    }
  };

  const handleLocationSelect = async (selectedLocation) => {
    let countryComponent = {};
    if (
      selectedLocation &&
      selectedLocation.value &&
      selectedLocation.value.place_id
    ) {
      try {
        const result = await geocodeByPlaceId(selectedLocation.value.place_id);
        countryComponent = result[0].address_components.find((component) =>
          component.types.includes("country")
        );
      } catch (error) {
        console.error("Error fetching place details:", error);
      }
    }
    setAnswers({
      ...answers,
      location: selectedLocation,
      country_code: countryComponent.short_name.toLowerCase(),
    });
    moveToNextQuestion();
  };

  const renderQuestion = (question) => {
    if (question.freeform) {
      return (
        <div className="flex items-center">
          <input
            type="text"
            value={answers[question.key] || ""}
            onChange={(e) =>
              setAnswers({ ...answers, [question.key]: e.target.value })
            }
            className="flex-1 w-full p-2 border rounded-bl rounded-tl"
            placeholder="Enter your answer"
          />
          <button
            onClick={moveToNextQuestion}
            className="bg-bikewise-green text-white font-bold py-2 px-4 rounded-br rounded-tr"
          >
            Submit <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      );
    }

    if (question.type === "location") {
      return (
        <div className="flex flex-col">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            selectProps={{
              value: answers.location,
              onChange: handleLocationSelect,
              placeholder: "Enter your location",
              styles: {
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0.375rem",
                  borderColor: "#E5E7EB",
                }),
              },
            }}
          />
        </div>
      );
    }

    if (question.type === "textarea") {
      return (
        <div className="flex flex-col">
          <textarea
            value={answers[question.key] || ""}
            onChange={(e) =>
              setAnswers({ ...answers, [question.key]: e.target.value })
            }
            className="w-full p-2 border rounded"
            placeholder="Enter any additional notes"
            rows="4"
          />
          <button
            onClick={moveToNextQuestion}
            className="bg-bikewise-green text-white font-bold py-2 px-4 rounded mt-2 self-end"
          >
            Submit <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      );
    }

    return (
      <CustomSelect
        question={question.question}
        options={question.options}
        value={answers[question.key] || ""}
        onChange={(value) => handleAnswer(value, question.key)}
        settings={settings}
      />
    );
  };

  const isFormValid = () => {
    return answers.condition && answers.estimatedMiles && answers.location;
  };

  const currentQuestion = questions[step];

  return (
    <div
      className="p-4 bg-white rounded-lg shadow-md"
      style={{
        backgroundColor: settings.backgroundColor,
        color: settings.textColor,
      }}
    >
      <div
        className={`transition-opacity duration-300 ease-in-out ${
          transitioning ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold">{currentQuestion.question}</h2>
        </div>
        {renderQuestion(currentQuestion)}
      </div>
      <div className="flex justify-between items-center mt-4">
        {step > 0 && (
          <button onClick={handleBack} className="text-sm text-gray-500">
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        )}
        <div className="text-sm text-gray-500">
          Question {step + 1} of {questions.length}
        </div>
      </div>
      {renderButton(isFormValid(), answers)}
    </div>
  );
};

export default ValuationForm;
