import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography, Button, Grid, AppBar, Toolbar } from "@mui/material";
import { useStore } from "../store";
import { fetchApiKeys, fetchAllRequests } from "../components/requests/api";
import { FilterComponents } from "../components/requests/filters";
import { DataGridComponent } from "../components/requests/dataGrid";
import { ChartComponents } from "../components/requests/chart";
import { JobDetailsModal } from "../components/requests/jobDetailsModal";
import { KeyStatsComponent } from "../components/requests/keyStats";
import {
  applyFilters,
  updateURL,
  parseURLParams,
} from "../components/requests/utils";

const RequestDashboard = () => {
  const [allRequests, setAllRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    endpoints: [],
    origins: [],
    companies: [],
    jobStatuses: [],
    ipAddresses: [],
    dateRange: "all",
    startDateTime: null,
    endDateTime: null,
  });
  const [filterOptions, setFilterOptions] = useState({
    endpoints: [],
    origins: [],
    jobStatuses: [],
    ipAddresses: [],
  });
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [selectedJobDetails, setSelectedJobDetails] = useState(null);
  const [jobLoading, setJobLoading] = useState(false);

  const token = useStore((state) => state.token);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (token) {
      fetchApiKeys(token).then(setApiKeys);
      fetchAllRequests(token).then((data) => {
        setAllRequests(data);
        updateFilterOptions(data);
      });
    }
  }, [token]);

  useEffect(() => {
    const newFilters = parseURLParams(searchParams);
    setFilters(newFilters);
  }, [searchParams]);

  useEffect(() => {
    const filteredData = applyFilters(allRequests, filters);
    setFilteredRequests(filteredData);
  }, [allRequests, filters]);

  const updateFilterOptions = (data) => {
    const endpoints = [...new Set(data.map((req) => req.endpoint))];
    const origins = [...new Set(data.map((req) => req.origin))];
    const jobStatuses = [...new Set(data.map((req) => req.job_status))];
    const ipAddresses = [...new Set(data.map((req) => req.ip_address))];

    setFilterOptions({
      endpoints,
      origins,
      jobStatuses,
      ipAddresses,
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [name]: Array.isArray(value) ? value : [value],
      };
      updateURL(newFilters, setSearchParams);
      return newFilters;
    });
  };

  const handleDateTimeChange = (name, dateTime) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [name]: dateTime ? dateTime.toDate() : null,
      };
      updateURL(newFilters, setSearchParams);
      return newFilters;
    });
  };

  const resetFilters = () => {
    const newFilters = {
      endpoints: [],
      origins: [],
      companies: [],
      jobStatuses: [],
      ipAddresses: [],
      dateRange: "all",
      startDateTime: null,
      endDateTime: null,
    };
    setFilters(newFilters);
    updateURL(newFilters, setSearchParams);
  };

  const handleIpAddressClick = (ipAddress) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        ipAddresses: [ipAddress],
      };
      updateURL(newFilters, setSearchParams);
      return newFilters;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#47888A",
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: "#333" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Data Dashboard
          </Typography>
          <FilterComponents
            filters={filters}
            filterOptions={filterOptions}
            apiKeys={apiKeys}
            handleFilterChange={handleFilterChange}
            handleDateTimeChange={handleDateTimeChange}
            resetFilters={resetFilters}
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 3 }}>
        <KeyStatsComponent requests={filteredRequests} />
        <ChartComponents
          requests={filteredRequests}
          onIpAddressClick={handleIpAddressClick}
        />
        <DataGridComponent
          filteredRequests={filteredRequests}
          loading={loading}
          setJobModalOpen={setJobModalOpen}
          setSelectedJobDetails={setSelectedJobDetails}
          setJobLoading={setJobLoading}
          token={token}
        />
      </Box>
      <JobDetailsModal
        open={jobModalOpen}
        handleClose={() => setJobModalOpen(false)}
        jobDetails={selectedJobDetails}
        loading={jobLoading}
      />
    </Box>
  );
};

export default RequestDashboard;
