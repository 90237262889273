import React, { useState } from "react";
import { useStore } from "../../store";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function CreateCompany() {
  const navigate = useNavigate();
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setToast = useStore((state) => state.setToast);

  // State for each field
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState(null);

  const handleCreateCompany = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/company`,
        {
          name,
          email,
          phone,
          website,
          address: address?.value.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        console.log("Company created:", response.data);
        navigate(`/company/?company=${response.data.id}`);
      }
    } catch (error) {
      console.error("Error creating company:", error);
      setToast({
        type: "error",
        message: "Error creating company. Please try again.",
      });
    }
  };

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-6 items-center">
          <button onClick={() => navigate("/profile")}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <h1 className="text-4xl font-extrabold">Create Company</h1>
        </div>
      </div>
      <div className="flex flex-col mt-4 p-8">
        <form className="flex flex-col gap-4">
          <div>
            {/* Name */}
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="name">
                Company Name
              </label>
              <input
                className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Phone */}
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="phone">
                Phone
              </label>
              <input
                className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            {/* Website */}
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="website">
                Website
              </label>
              <input
                className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                id="website"
                type="url"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>

            {/* Address with Google Places Autocomplete */}
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="address">
                Address
              </label>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  placeholder: "Enter company address",
                  onChange: setAddress,
                  value: address,
                  className:
                    "border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green",
                }}
                inputClassName="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
              />
            </div>
          </div>

          <button
            className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
            type="button"
            onClick={handleCreateCompany}
          >
            <FontAwesomeIcon icon={faBuilding} className="mr-4" />
            Create Company
          </button>
        </form>
      </div>
    </div>
  );
}
