export const getEndpointLabel = (endpoint) => {
  if (endpoint === "/sheldon/identification") {
    return "Bike Identification";
  } else if (endpoint.startsWith("/widget/")) {
    return "Widget Load";
  } else {
    return endpoint;
  }
};

export const applyFilters = (allRequests, filters) => {
  let result = [...allRequests];

  if (filters.endpoints.length > 0) {
    result = result.filter((req) => filters.endpoints.includes(req.endpoint));
  }

  if (filters.origins.length > 0) {
    result = result.filter((req) => filters.origins.includes(req.origin));
  }

  if (filters.companies.length > 0) {
    result = result.filter((req) =>
      filters.companies.includes(req.api_key?.company?.id)
    );
  }

  if (filters.jobStatuses.length > 0) {
    result = result.filter((req) =>
      filters.jobStatuses.includes(req.job_status)
    );
  }

  if (
    filters.dateRange === "custom" &&
    filters.startDateTime &&
    filters.endDateTime
  ) {
    result = result.filter((req) => {
      const requestDate = new Date(req.timestamp);
      return (
        requestDate >= filters.startDateTime &&
        requestDate <= filters.endDateTime
      );
    });
  } else if (filters.dateRange !== "all") {
    const now = new Date();
    let startDate;

    switch (filters.dateRange) {
      case "1h":
        startDate = new Date(now.getTime() - 60 * 60 * 1000);
        break;
      case "24h":
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "7d":
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "30d":
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      default:
        startDate = null;
    }

    if (startDate) {
      result = result.filter((req) => new Date(req.timestamp) >= startDate);
    }
  }

  return result;
};

export const updateURL = (filters, setSearchParams) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v));
    } else if (
      value !== null &&
      value !== "" &&
      key !== "startDateTime" &&
      key !== "endDateTime"
    ) {
      params.set(key, value);
    }
  });

  if (filters.startDateTime) {
    params.set("startDateTime", filters.startDateTime.toISOString());
  }
  if (filters.endDateTime) {
    params.set("endDateTime", filters.endDateTime.toISOString());
  }

  setSearchParams(params);
};

export const parseURLParams = (searchParams) => {
  return {
    endpoints: searchParams.getAll("endpoints"),
    origins: searchParams.getAll("origins"),
    companies: searchParams.getAll("companies"),
    jobStatuses: searchParams.getAll("jobStatuses"),
    dateRange: searchParams.get("dateRange") || "all",
    startDateTime: searchParams.get("startDateTime")
      ? new Date(searchParams.get("startDateTime"))
      : null,
    endDateTime: searchParams.get("endDateTime")
      ? new Date(searchParams.get("endDateTime"))
      : null,
  };
};
