import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBuilding,
  faGears,
  faRightFromBracket,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar({ setTourOpen }) {
  const userInfo = useStore((state) => state.userInfo);
  const { clearAuth } = useStore();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOAuthLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
  };

  return (
    <div className="h-auto md:h-screen flex flex-col justify-between items-center pt-8 pb-8 w-full gap-4 md:gap-0">
      <Link to={userInfo ? "/profile?screen=bikes" : "/"} className="w-full">
        <img src="/images/logos/fullAPI.svg" alt="BikeWise" className="w-1/2" />
      </Link>
      {mobile && (
        <div className="w-full">
          <button
            className="w-full border-2 border-bikewise-red text-bikewise-red text-center font-bold rounded-lg py-2 hover:scale-105 transition duration-150 ease-in-out"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faBars} /> Menu
          </button>
        </div>
      )}
      <div
        className={`flex flex-col justify-between h-full w-full ${
          mobile && !isSidebarOpen ? "hidden" : "mt-2 rounded-xl"
        }`}
      >
        {!mobile && (
          <div className="h-full flex flex-col justify-end mb-8">
            <div className="flex flex-col text-center gap-2">
              {/* 

              <a
                href="https://bikewise.canny.io/feedback"
                target="_blank"
                rel="noreferrer"
                data-canny-link
              >
                <button className="w-full p-4 rounded-xl bg-bikewise-azure flex gap-4 items-center justify-center hover:bg-bikewise-lightGreen transition duration-150 ease-in-ou">
                  <FontAwesomeIcon icon={faCommentDots} beat /> Feedback
                </button>
              </a>
              
              
              */}
              {userInfo &&
                userInfo.companies &&
                userInfo.companies.length > 0 && (
                  <div>
                    <Link
                      to={`/company?id=${userInfo.companies[0].company.id}`}
                    >
                      <div className="p-4 rounded-xl bg-bikewise-azure flex gap-4 items-center justify-center">
                        <FontAwesomeIcon icon={faBuilding} />{" "}
                        {userInfo.companies[0].company.name}
                      </div>
                    </Link>
                  </div>
                )}

              {userInfo.roles && userInfo.roles.includes("admin") && (
                <div>
                  <Link to="/admin">
                    <div className="p-4 rounded-xl bg-bikewise-red flex gap-4 items-center justify-center">
                      <FontAwesomeIcon icon={faToolbox} /> Admin
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}

        {userInfo && (
          <div className="flex gap-4 items-center w-full mt-auto">
            <Link to="/profile?screen=bikes">
              <div className="flex items-center gap-4">
                {userInfo && userInfo.profile_image_url ? (
                  <img
                    className="h-20 w-20 rounded-full mb-2"
                    src={userInfo.profile_image_url}
                    alt=""
                  />
                ) : (
                  <div className="h-20 w-20 rounded-full bg-gray-500 text-white flex items-center justify-center font-bold">
                    {userInfo?.name ? userInfo.name[0].toUpperCase() : "P"}
                  </div>
                )}
              </div>
            </Link>
            <div className="flex flex-col w-full gap-2">
              <div>
                <h2 className="text-lg font-semibold text-black">
                  {userInfo.name}
                </h2>
                <p className="text-sm text-black">
                  {userInfo.email}
                  {userInfo.address?.terms[2] &&
                    userInfo.address?.terms[3] &&
                    userInfo.address?.terms[4] && (
                      <>
                        <br />
                        <span>
                          {userInfo.address.terms[2].value},{" "}
                          {userInfo.address.terms[3].value},{" "}
                          {userInfo.address.terms[4].value}
                        </span>
                      </>
                    )}
                </p>
              </div>
              <div className="flex justify-between text-black w-full">
                <Link className="" to="/profile?screen=settings">
                  <FontAwesomeIcon icon={faGears} /> Settings
                </Link>
                <Link className="" to="/" onClick={() => clearAuth()}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> Sign Out
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
