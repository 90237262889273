import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

const ApiDashboard = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [dateRange, setDateRange] = useState("24hours");

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/companies"
      );
      const companiesWithTotalCostAndRequests = response.data.map(
        (company) => ({
          ...company,
          totalCost: calculateTotalCost(company.requests),
          totalRequests: company.requests.length,
        })
      );
      setCompanies(companiesWithTotalCostAndRequests);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const handleCompanyClick = (companyId) => {
    setSelectedCompany(companyId);
  };

  const handleBackClick = () => {
    setSelectedCompany(null);
  };

  const calculateTotalCost = (requests) => {
    return requests.reduce(
      (total, request) => total + (request.variable_costs?.totalCost || 0),
      0
    );
  };

  const companyColumns = [
    { field: "name", headerName: "Company", width: 200 },
    { field: "apiKey", headerName: "API Key", width: 300 },
    { field: "totalRequests", headerName: "Total Requests", width: 150 },
    { field: "totalCost", headerName: "Total Cost", width: 150 },
  ];

  const requestColumns = [
    { field: "endpoint", headerName: "Endpoint", width: 300 },
    { field: "totalRequests", headerName: "Total Requests", width: 150 },
    { field: "totalCost", headerName: "Total Cost", width: 150 },
  ];

  const filteredRequests = (requests) => {
    const startDate = new Date();
    switch (dateRange) {
      case "1hour":
        startDate.setHours(startDate.getHours() - 1);
        break;
      case "24hours":
        startDate.setDate(startDate.getDate() - 1);
        break;
      case "7days":
        startDate.setDate(startDate.getDate() - 7);
        break;
      case "30days":
        startDate.setDate(startDate.getDate() - 30);
        break;
      default:
        break;
    }
    return requests.filter(
      (request) => new Date(request.timestamp) >= startDate
    );
  };

  if (selectedCompany) {
    const company = companies.find((c) => c.id === selectedCompany);
    const companyRequests = filteredRequests(company.requests).reduce(
      (acc, request) => {
        const existingEndpoint = acc.find(
          (r) => r.endpoint === request.endpoint
        );
        if (existingEndpoint) {
          existingEndpoint.totalRequests += 1;
          existingEndpoint.totalCost += request.variable_costs?.totalCost || 0;
        } else {
          acc.push({
            endpoint: request.endpoint,
            totalRequests: 1,
            totalCost: request.variable_costs?.totalCost || 0,
          });
        }
        return acc;
      },
      []
    );

    return (
      <div className="p-4">
        <button
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
          onClick={handleBackClick}
        >
          Back to Companies
        </button>
        <h2 className="text-2xl font-bold mb-4">{company.name} Requests</h2>
        <DataGrid
          rows={companyRequests}
          columns={requestColumns}
          getRowId={(row) => row.endpoint}
        />
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">API Dashboard</h1>
      <select
        className="mb-4 px-4 py-2 border border-gray-300 rounded"
        value={dateRange}
        onChange={handleDateRangeChange}
      >
        <option value="1hour">Last Hour</option>
        <option value="24hours">Last 24 Hours</option>
        <option value="7days">Last 7 Days</option>
        <option value="30days">Last 30 Days</option>
      </select>
      <DataGrid
        rows={companies}
        columns={companyColumns}
        onRowClick={(params) => handleCompanyClick(params.row.id)}
      />
    </div>
  );
};

export default ApiDashboard;
