import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobile,
  faQuestion,
  faExclamationTriangle,
  faCheck,
  faBan,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWindows,
  faApple,
  faAndroid,
  faLinux,
} from "@fortawesome/free-brands-svg-icons";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatCard = ({ title, values }) => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Typography variant="h6" component="div" gutterBottom>
        {title}
      </Typography>
      {Object.entries(values).map(([key, value]) => (
        <Typography key={key} variant="body1" component="div">
          {key}: {typeof value === "number" ? value.toFixed(2) : value}
          {key.includes("Percentage") ? "%" : key.includes("Time") ? " s" : ""}
        </Typography>
      ))}
    </CardContent>
  </Card>
);

const calculateStats = (requests) => {
  const bikeIdentificationRequests = requests.filter(
    (req) => req.endpoint === "/sheldon/identification" && req.job_id
  );
  const widgetLoadRequests = requests.filter((req) =>
    req.endpoint.startsWith("/widget/")
  ).length;

  const incorrectFileRequests = requests.filter(
    (req) => req.endpoint === "/sheldon/identification" && !req.job_id
  ).length;

  const percentageBikeIdentification =
    (bikeIdentificationRequests.length / widgetLoadRequests) * 100;

  const validRequestTimes = bikeIdentificationRequests
    .filter((req) => req.total_request_time != null)
    .map((req) => parseFloat(req.total_request_time) / 1000);

  validRequestTimes.sort((a, b) => a - b);

  const averageRequestTime =
    validRequestTimes.length > 0
      ? validRequestTimes.reduce((sum, time) => sum + time, 0) /
        validRequestTimes.length
      : 0;

  const medianRequestTime =
    validRequestTimes.length > 0
      ? validRequestTimes[Math.floor(validRequestTimes.length / 2)]
      : 0;

  const fastestTenPercent =
    validRequestTimes.length > 0
      ? validRequestTimes[Math.floor(validRequestTimes.length * 0.1)]
      : 0;

  const slowestTenPercent =
    validRequestTimes.length > 0
      ? validRequestTimes[Math.floor(validRequestTimes.length * 0.9)]
      : 0;

  // Calculate standard deviation
  const variance =
    validRequestTimes.reduce(
      (sum, time) => sum + Math.pow(time - averageRequestTime, 2),
      0
    ) / validRequestTimes.length;
  const standardDeviation = Math.sqrt(variance);

  const redirectStats = {
    notRedirected: 0,
    customRedirect: 0,
    standardRedirect: 0,
    bikeBrandNotFound: 0,
  };

  bikeIdentificationRequests.forEach((req) => {
    if (!req.successful_redirect && req.redirect_url) {
      redirectStats.notRedirected++;
    } else if (
      req.redirect_url &&
      req.redirect_url.toLowerCase().includes("bike-brand-not-found")
    ) {
      redirectStats.bikeBrandNotFound++;
    } else if (
      req.redirect_url &&
      req.redirect_url.toLowerCase().includes("bikewise")
    ) {
      redirectStats.customRedirect++;
    } else {
      redirectStats.standardRedirect++;
    }
  });

  const deviceStats = {
    mobile: 0,
    desktop: 0,
    types: {},
  };

  requests.forEach((req) => {
    const userAgent = req.user_agent?.toLowerCase() || "";
    if (
      userAgent.includes("mobile") ||
      userAgent.includes("android") ||
      userAgent.includes("iphone")
    ) {
      deviceStats.mobile++;
    } else {
      deviceStats.desktop++;
    }

    let deviceType = "Unknown";
    if (userAgent.includes("macintosh")) deviceType = "macOS";
    else if (userAgent.includes("windows")) deviceType = "Windows";
    else if (userAgent.includes("android")) deviceType = "Android";
    else if (userAgent.includes("iphone")) deviceType = "iPhone";
    else if (userAgent.includes("linux")) deviceType = "Linux";

    deviceStats.types[deviceType] = (deviceStats.types[deviceType] || 0) + 1;
  });

  const totalRequests = requests.length;
  const percentageMobile = (deviceStats.mobile / totalRequests) * 100;

  return {
    percentageBikeIdentification,
    requestTimes: {
      average: averageRequestTime,
      median: medianRequestTime,
      fastest10: fastestTenPercent,
      slowest10: slowestTenPercent,
      standardDeviation,
    },
    redirectStats,
    totalBikeIdentifications: bikeIdentificationRequests.length,
    percentageMobile,
    deviceTypes: deviceStats.types,
    totalRequests,
    incorrectFileRequests,
  };
};

const getDeviceIcon = (deviceType) => {
  switch (deviceType.toLowerCase()) {
    case "windows":
      return faWindows;
    case "macos":
      return faApple;
    case "android":
      return faAndroid;
    case "iphone":
      return faApple;
    case "linux":
      return faLinux;
    case "desktop":
      return faDesktop;
    case "mobile":
      return faMobile;
    default:
      return faQuestion;
  }
};

const RedirectQuadrant = ({ title, count, total, icon }) => (
  <Box sx={{ textAlign: "center", p: 2 }}>
    <FontAwesomeIcon
      icon={icon}
      style={{ fontSize: "2rem", marginBottom: "0.5rem" }}
    />
    <Typography variant="h6" component="div">
      {title}
    </Typography>
    <Typography variant="h4" component="div">
      {count}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {((count / total) * 100).toFixed(2)}%
    </Typography>
  </Box>
);

const BellCurve = ({ mean, standardDeviation }) => {
  const generateNormalDistribution = (mean, stdDev, points = 100) => {
    const data = [];
    const labels = [];
    const range = 4 * stdDev;
    const step = range / points;

    for (let x = mean - 2 * stdDev; x <= mean + 2 * stdDev; x += step) {
      const y =
        (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
      data.push(y);
      labels.push(x.toFixed(2));
    }

    return { data, labels };
  };

  const { data, labels } = generateNormalDistribution(mean, standardDeviation);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Normal Distribution",
        data: data,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Response Time Distribution",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label +=
                context.parsed.x + "s, Density: " + context.parsed.y.toFixed(4);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Response Time (s)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Probability Density",
        },
      },
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Line data={chartData} options={options} />
        <Typography variant="body2" color="text.secondary">
          Red line: Mean, Blue dashed lines: ±1 Standard Deviation
        </Typography>
      </CardContent>
    </Card>
  );
};

export const KeyStatsComponent = ({ requests }) => {
  const stats = calculateStats(requests);

  const sortedDeviceTypes = Object.entries(stats.deviceTypes).sort(
    ([, a], [, b]) => b - a
  );

  return (
    <Box sx={{ flexGrow: 1, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Key Statistics
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Bike Identifications"
            values={{
              Total: stats.totalBikeIdentifications,
              "Incorrect file type": stats.incorrectFileRequests,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Widget Usage"
            values={{ Percentage: stats.percentageBikeIdentification }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Response Time"
            values={{
              "Average Time": stats.requestTimes.average,
              "Median Time": stats.requestTimes.median,
              "Fastest 10% Time": stats.requestTimes.fastest10,
              "Slowest 10% Time": stats.requestTimes.slowest10,
              "Standard Deviation": stats.requestTimes.standardDeviation,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BellCurve
            mean={stats.requestTimes.average}
            standardDeviation={stats.requestTimes.standardDeviation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Redirect URL Distribution
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <RedirectQuadrant
                    title="Template Redirect"
                    count={stats.redirectStats.standardRedirect}
                    total={stats.totalBikeIdentifications}
                    icon={faCheck}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RedirectQuadrant
                    title="Custom Redirect"
                    count={stats.redirectStats.customRedirect}
                    total={stats.totalBikeIdentifications}
                    icon={faExclamationTriangle}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RedirectQuadrant
                    title="Brand Not Found"
                    count={stats.redirectStats.bikeBrandNotFound}
                    total={stats.totalBikeIdentifications}
                    icon={faSearch}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RedirectQuadrant
                    title="Not Redirected"
                    count={stats.redirectStats.notRedirected}
                    total={stats.totalBikeIdentifications}
                    icon={faBan}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                Device Types
              </Typography>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FontAwesomeIcon
                    icon={faMobile}
                    style={{ marginRight: "8px" }}
                  />
                  Mobile Usage: {stats.percentageMobile.toFixed(2)}% (
                  {Math.round(
                    (stats.totalRequests * stats.percentageMobile) / 100
                  )}{" "}
                  requests)
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" gutterBottom>
                Detailed Breakdown:
              </Typography>
              {sortedDeviceTypes.map(([type, count]) => (
                <Typography
                  key={type}
                  variant="body2"
                  component="div"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <FontAwesomeIcon
                    icon={getDeviceIcon(type)}
                    style={{ marginRight: "8px" }}
                  />
                  {type}: {((count / stats.totalRequests) * 100).toFixed(2)}% (
                  {count} requests)
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
