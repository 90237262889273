import React from "react";
import CompanyDashboard from "../components/company";

const CompanyPage = () => {
  return (
    <div>
      <CompanyDashboard />
    </div>
  );
};

export default CompanyPage;
