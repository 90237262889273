import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios"; // Make sure to import axios
import { useStore } from "../store";

export default function AuthCallback() {
  const setAuth = useStore((state) => state.setAuth);
  let navigate = useNavigate();
  const location = useLocation();

  const setIsLoading = useStore((state) => state.setIsLoading);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (token) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/user",
            {},
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );
          const userInfo = response.data;
          // Update auth state using Zustand
          setAuth(token, userInfo);
          navigate("/profile?screen=bikes");
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to fetch user profile:", error);
        }
      } else {
        // Handle error: token was not provided
        console.error("Token was not provided in the callback URL.");
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return null; // This component doesn't render anything
}
