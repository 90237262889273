export function camelToTitle(camelStr) {
  return camelStr
    .replace(/([A-Z])/g, " $1") // insert a space before all capital letters
    .replace(/^./, (str) => str.toUpperCase()); // uppercase the first character
}

export function toTitleCase(str) {
  return str
    .toLowerCase() // Convert the entire string to lowercase first
    .split(" ") // Split the string into an array of words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }) // Capitalize the first letter of each word, and concatenate with the rest of the word
    .join(" "); // Join the array of words back into a single string
}
