import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBicycle,
  faIndustry,
  faCog,
  faTools,
  faMessage,
  faClipboardList,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ activeSection, setActiveSection }) => {
  return (
    <div className="bg-gray-800 text-white py-4 px-4 flex flex-col justify-between rounded-lg ">
      <div>
        <h2 className="text-xl font-bold mb-4">Admin Dashboard</h2>
        <nav>
          <ul>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "allUsers" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("allUsers")}
            >
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              All Users
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "allBikes" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("allBikes")}
            >
              <FontAwesomeIcon icon={faBicycle} className="mr-2" />
              All Bikes
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "allManufacturers" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("allManufacturers")}
            >
              <FontAwesomeIcon icon={faIndustry} className="mr-2" />
              All Manufacturers
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "allParts" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("allParts")}
            >
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              All Parts
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "allPartTypes" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("allPartTypes")}
            >
              <FontAwesomeIcon icon={faTools} className="mr-2" />
              All Part Types
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "sheldonThreads" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("sheldonThreads")}
            >
              <FontAwesomeIcon icon={faMessage} className="mr-2" />
              Sheldon Threads
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "bikeIdentificationJobs" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("bikeIdentificationJobs")}
            >
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
              Bike Identification Jobs
            </li>
            <li
              className={`py-2 px-4 rounded hover:bg-gray-700 cursor-pointer ${
                activeSection === "bikeValuationJobs" ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveSection("bikeValuationJobs")}
            >
              <FontAwesomeIcon icon={faChartLine} className="mr-2" />
              Bike Valuation Jobs
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
