import axios from "axios";

const createAxiosInstance = (token) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchApiKeys = async (token) => {
  try {
    const axiosInstance = createAxiosInstance(token);
    const response = await axiosInstance.get("/api-keys");
    return response.data;
  } catch (error) {
    console.error("Error fetching API keys:", error);
    throw error;
  }
};

export const fetchAllRequests = async (token) => {
  try {
    const axiosInstance = createAxiosInstance(token);
    const response = await axiosInstance.get("/api-requests");
    return response.data;
  } catch (error) {
    console.error("Error fetching requests:", error);
    throw error;
  }
};

export const fetchJobDetails = async (token, jobId, endpoint) => {
  try {
    const axiosInstance = createAxiosInstance(token);
    let jobDetailsUrl;
    if (endpoint === "/sheldon/identification") {
      jobDetailsUrl = `/sheldon/identification/${jobId}`;
    } else if (endpoint.startsWith("/widget/")) {
      jobDetailsUrl = `/widget/${jobId}`;
    } else {
      jobDetailsUrl = `/jobs/${jobId}`;
    }
    const response = await axiosInstance.get(jobDetailsUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching job details:", error);
    throw error;
  }
};
