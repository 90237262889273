import React, { useState } from "react";
import { useStore } from "../../store"; // Adjust the path accordingly
import axios from "axios";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faWrench } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

export default function Settings() {
  const navigate = useNavigate();
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setToast = useStore((state) => state.setToast);
  const setUserInfo = useStore((state) => state.setUserInfo);

  // State for each field
  const [name, setName] = useState(userInfo.name);
  const [email, setEmail] = useState(userInfo.email);
  const [currentPassword, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [address, setAddress] = useState(userInfo.address?.description);

  const [profileImage, setProfileImage] = useState(null);

  // Update handler (to be implemented)
  const handleUpdate = async () => {
    // The endpoint to your update user route (modify this to match your backend route)
    const endpoint = process.env.REACT_APP_API_URL + "/user/update";

    try {
      // The request payload
      const data = {
        id: userInfo.id,
        values: {
          name,
          email,
          address: address?.value,
        },
      };

      // If there's a new password and current password, include it in the update
      if (newPassword && currentPassword) {
        data.values.currentPassword = currentPassword;
        data.values.newPassword = newPassword;
      }

      // Make the API call
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the user info in the store
      setUserInfo(response.data);
      setPassword(null);
      setNewPassword(null);
      setToast({ type: "success", message: "User info updated successfully!" });
    } catch (error) {
      console.error("Error updating profile:", error);
      setToast({
        type: "error",
        message: "Error updating profile. Please try again.",
      });
    }
  };

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleProfileImageUpload = async () => {
    if (!profileImage) {
      setToast({ type: "error", message: "No image selected." });
      return;
    }

    const formData = new FormData();
    formData.append("image", profileImage);

    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/user/update/image`; // Adjust the endpoint as needed
      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Update the user info in the store if needed
      setUserInfo({
        ...userInfo,
        profile_image_url: response.data.profile_image_url,
      }); // Adjust as per your response structure
      setProfileImage(null);
      setToast({
        type: "success",
        message: "Profile image updated successfully!",
      });
    } catch (error) {
      console.error("Error updating profile image:", error);
      setToast({
        type: "error",
        message: "Error updating profile image. Please try again.",
      });
    }
  };

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-6 items-center">
          <button onClick={() => navigate("/profile?screen=bikes")}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <h1 className="text-4xl font-extrabold">Settings</h1>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
            type="button"
            onClick={handleUpdate}
          >
            <FontAwesomeIcon icon={faWrench} className="mr-4" />
            Update Profile Settings
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-4 p-8">
        {/* User Image on the Left */}
        <div className="w-1/4 mr-8">
          {userInfo && userInfo.profile_image_url ? (
            <img
              className="min-h-50 min-w-50 h-[15vw] w-[15vw] rounded-full mb-2"
              src={userInfo.profile_image_url}
              alt=""
            />
          ) : (
            <div className="min-h-50 min-w-50 h-[15vw] w-[15vw] rounded-full bg-gray-500 text-white flex items-center justify-center font-bold text-lg">
              {userInfo?.name ? userInfo.name[0].toUpperCase() : "P"}
            </div>
          )}
          <div className="flex flex-col gap-2 w-full">
            <input type="file" onChange={handleImageChange} accept="image/*" />
            <button
              className="w-full bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
              onClick={handleProfileImageUpload}
            >
              Upload New Image
            </button>
          </div>
        </div>

        {/* User Details on the Right */}
        <div className="w-3/4">
          <form className="flex flex-col gap-4">
            <div>
              <h2 className="text-2xl font-bold mb-4">User Details</h2>
              {/* Name */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Address with Google Places Autocomplete */}
              <div className="mb-4">
                <div className="flex gap-2 items-center">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="location"
                  >
                    Location
                  </label>
                </div>
                <div className="w-full">
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      placeholder: address || "Enter your address",
                      onChange: setAddress,
                      className:
                        "rounded-lg text-gray-700 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green",
                    }}
                    inputClassName="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold mb-4">Authentication</h2>
              {(userInfo.strava_id ||
                userInfo.google_id ||
                userInfo.apple_id) &&
              !userInfo.password ? (
                <div className="mb-4">
                  <p className="text-sm text-gray-500">
                    You are currently logged in with{" "}
                    {userInfo.strava_id ? "Strava" : ""}
                    {userInfo.google_id ? "Google" : ""}
                    {userInfo.apple_id ? "Apple" : ""}.
                  </p>
                </div>
              ) : (
                <div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-bold mb-2"
                      htmlFor="password"
                    >
                      Current Password
                    </label>
                    <input
                      className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                      id="password"
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      className="block text-sm font-bold mb-2"
                      htmlFor="newPassword"
                    >
                      New Password
                    </label>
                    <input
                      className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                      id="newPassword"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
