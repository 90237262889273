import React from "react";

const CookiePolicy = () => {
  return (
    <div className="bg-white p-6 m-4 border rounded shadow-md">
      <h1 className="text-xl font-semibold mb-4">Cookie Policy</h1>
      <p className="mb-4">
        We use cookies to enhance your experience on our website. Cookies help
        us to provide necessary site functionality and improve your online
        experience.
      </p>
      <h2 className="text-lg font-semibold mb-2">Types of Cookies We Use</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          Functional Cookies: Enables the website to function properly.
        </li>
        <li className="mb-2">
          Analytical Cookies: Helps us to analyze user behavior on our site to
          enhance user experience.
        </li>
      </ul>
      <p className="mb-4">
        By continuing to use our website, you agree to our use of cookies. You
        can withdraw your consent at any time by clearing the cookies in your
        browser.
      </p>
    </div>
  );
};

export default CookiePolicy;
