import React from "react";

const TermsOfService = () => {
  return (
    <div className="w-full flex items-center justify-center p-8">
      <div className="w-2/3">
        <h1 className="text-xl font-semibold mb-4">Terms of Service</h1>
        <h2 className="text-lg font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing and using the BikeWise website located at
          https://app.bikewise.ai (the "Site"), you agree to comply with and be
          bound by these Terms of Service (TOS). If you do not agree to these
          TOS, you are not authorized to use this Site.
        </p>
        <h2 className="text-lg font-semibold mb-2">2. Modification of Terms</h2>
        <p className="mb-4">
          BikeWise reserves the right, at its sole discretion, to modify or
          replace these TOS at any time. It is your responsibility to check the
          TOS periodically for changes. Your continued use of the Site following
          the posting of any changes to the TOS constitutes acceptance of those
          changes.
        </p>
        <h2 className="text-lg font-semibold mb-2">3. Use Restrictions</h2>
        <p className="mb-4">
          a) Unauthorized scraping, data-mining, or any other form of data
          extraction from the Site is strictly prohibited.
          <br /> b) Automated access, without written permission, is prohibited.{" "}
          <br />
          c) Any attempt to interrupt the operation of the Site, or its
          services, is prohibited.
        </p>
        <h2 className="text-lg font-semibold mb-2">4. Disclaimers</h2>
        <p className="mb-4">
          a) No Warranty: All information provided on the Site is for
          informational purposes only and does not constitute professional
          advice. While BikeWise endeavors to provide accurate and up-to-date
          information, it makes no warranties or representations as to the
          accuracy, reliability, or completeness of the Site's content.
          <br /> b) Compatibility Information: BikeWise provides compatibility
          information with no guarantee of correctness. Users should always
          conduct their own research and due diligence when relying on
          compatibility data. BikeWise will not be held liable for any damages
          or losses arising from the use or misuse of the compatibility
          information provided.
        </p>
        <h2 className="text-lg font-semibold mb-2">
          5. Limitation of Liability
        </h2>
        <p className="mb-4">
          In no event shall BikeWise, its officers, directors, employees, or
          agents be liable for any indirect, punitive, special, incidental, or
          consequential damages whatsoever, including, without limitation, lost
          profits, arising out of or connected with the use or misuse of the
          Site.
        </p>
        <h2 className="text-lg font-semibold mb-2">6. Indemnification</h2>
        <p className="mb-4">
          You agree to defend, indemnify, and hold harmless BikeWise, its
          officers, directors, employees, and agents from and against any
          claims, actions, or demands, including, without limitation, reasonable
          legal and accounting fees, arising or resulting from your breach of
          these TOS or your access to, use, or misuse of the Site.
        </p>
        <h2 className="text-lg font-semibold mb-2">7. Termination</h2>
        <p className="mb-4">
          BikeWise reserves the right to terminate your access to the Site at
          any time, without notice, for conduct that BikeWise believes violates
          these TOS or is harmful to other users, BikeWise, or third parties, or
          for any other reason.
        </p>
        <h2 className="text-lg font-semibold mb-2">
          8. Governing Law & Jurisdiction
        </h2>
        <p className="mb-4">
          These TOS are governed by and construed in accordance with the laws of
          the jurisdiction in which BikeWise operates, without regard to its
          conflict of law principles. You agree to submit to the exclusive
          jurisdiction of the courts located in that jurisdiction.
        </p>
        <h2 className="text-lg font-semibold mb-2">9. Contact Information</h2>
        <p className="mb-4">
          For any queries regarding these TOS, please contact us at
          hello@bikewise.ai.
        </p>
        <p className="">
          Last updated: <span className="font-semibold">10/28/2023</span>
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
