import React from "react";
import { useStore } from "../../store";
import { Link } from "react-router-dom";
import axios from "axios";

const CookieConsentPopup = () => {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const userInfo = useStore((state) => state.userInfo);
  const setAuth = useStore((state) => state.setAuth);
  const token = useStore((state) => state.token);

  const updateProfile = async (value) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/user/update",
        {
          id: userInfo.id,
          values: { cookie_accepted: value },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching bike info:", error);
    }
  };

  const handleAcceptCookies = () => {
    const updatedUserInfo = { ...userInfo, cookie_accepted: true };
    updateProfile(true);
    setAuth(token, updatedUserInfo);
  };

  const handleRejectCookies = () => {
    const updatedUserInfo = { ...userInfo, cookie_accepted: false };
    updateProfile(false);
    setAuth(token, updatedUserInfo);
  };

  if (isAuthenticated && userInfo && userInfo.cookie_accepted === null) {
    return (
      <div className="fixed bottom-0 right-0 w-1/2 bg-white p-4 m-4 rounded-lg shadow-lg">
        <p className="mb-2 text-black">
          We utilize cookies to improve your experience across the entire
          Bikewise ecosystem. To find out more, please read our{" "}
          <Link to="/legal/privacy-policy">Privacy Policy</Link> and{" "}
          <Link to="/legal/cookie-policy">Cookie Policy</Link>
        </p>
        <div className="flex gap-4">
          <button
            onClick={handleAcceptCookies}
            className="bg-bikewise-green text-white font-bold py-4 px-10 rounded-xl shadow-lg transform transition hover:scale-105"
          >
            Accept
          </button>
          <button
            onClick={handleRejectCookies}
            className="bg-bikewise-red text-white font-bold py-4 px-10 rounded-xl shadow-lg transform transition hover:scale-105"
          >
            Reject
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default CookieConsentPopup;
