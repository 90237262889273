import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

import ThreadMessages from "./threadMessages";
import JobDetails from "./jobDetails";

const DataTable = ({ activeSection, token }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    fetchData();
  }, [activeSection]);

  const getEndpoint = (section) => {
    switch (section) {
      case "allUsers":
        return "users";
      case "allBikes":
        return "bikes";
      case "allManufacturers":
        return "manufacturers";
      case "allParts":
        return "parts";
      case "allPartTypes":
        return "part-types";
      case "sheldonThreads":
        return "threads";
      case "bikeIdentificationJobs":
        return "admin/jobs/all";
      case "bikeValuationJobs":
        return "admin/jobs/all";
      default:
        return section;
    }
  };

  const fetchData = async () => {
    try {
      const endpoint = getEndpoint(activeSection);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        activeSection === "bikeIdentificationJobs" ||
        activeSection === "bikeValuationJobs"
      ) {
        const queueName =
          activeSection === "bikeIdentificationJobs"
            ? "bikeIdentification"
            : "bikeValuation";
        const allJobs = [];
        Object.entries(response.data[queueName]).forEach(([type, jobs]) => {
          jobs.forEach((job) => {
            allJobs.push({
              ...job,
              type,
              id: `${job.id}-${type}`,
              state: type,
            });
          });
        });
        setData(
          allJobs.sort((a, b) => (b.completed_at || 0) - (a.completed_at || 0))
        );
      } else {
        setData(response.data);
      }

      setColumns(getColumns(activeSection));
    } catch (error) {
      console.error(`Error fetching ${activeSection}:`, error);
    }
  };

  const getColumns = (section) => {
    switch (section) {
      case "allUsers":
        return [
          { field: "id", headerName: "ID", width: 200 },
          { field: "name", headerName: "Name", width: 200 },
          { field: "email", headerName: "Email", width: 250 },
          // Add more columns for users
        ];
      case "allBikes":
        return [
          { field: "id", headerName: "ID", width: 200 },
          { field: "name", headerName: "Name", width: 200 },
          { field: "manufacturer", headerName: "Manufacturer", width: 250 },
          // Add more columns for bikes
        ];
      case "allManufacturers":
        return [
          { field: "id", headerName: "ID", width: 200 },
          { field: "name", headerName: "Name", width: 200 },
          { field: "country", headerName: "Country", width: 250 },
          // Add more columns for manufacturers
        ];
      case "allParts":
        return [
          { field: "id", headerName: "ID", width: 200 },
          { field: "name", headerName: "Name", width: 200 },
          { field: "category", headerName: "Category", width: 250 },
          // Add more columns for parts
        ];
      case "allPartTypes":
        return [
          { field: "id", headerName: "ID", width: 200 },
          { field: "name", headerName: "Name", width: 200 },
          { field: "category", headerName: "Category", width: 250 },
          // Add more columns for part types
        ];
      case "sheldonThreads":
        return [
          { field: "id", headerName: "ID", width: 100 },
          { field: "thread_id", headerName: "Thread ID", width: 150 },
          { field: "user_id", headerName: "User ID", width: 150 },
          { field: "title", headerName: "Title", width: 200 },
          {
            field: "is_active",
            headerName: "Is Active",
            width: 100,
            type: "boolean",
          },
          {
            field: "totalMessages",
            headerName: "Total Messages",
            width: 150,
            type: "number",
          },
          {
            field: "last_assistant_id",
            headerName: "Last Assistant ID",
            width: 150,
          },
          {
            field: "variable_costs",
            headerName: "Variable Costs",
            width: 150,
            valueGetter: (params) => JSON.stringify(params.value),
          },
          {
            field: "context",
            headerName: "Context",
            width: 150,
            valueGetter: (params) => JSON.stringify(params.value),
          },
          {
            field: "last_message_timestamp",
            headerName: "Last Message Timestamp",
            width: 200,
          },
          {
            field: "created_at",
            headerName: "Created At",
            width: 200,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
          },
          {
            field: "updated_at",
            headerName: "Updated At",
            width: 200,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
          },
        ];
      case "sheldonThreads":
        return [
          { field: "id", headerName: "ID", width: 100 },
          { field: "thread_id", headerName: "Thread ID", width: 150 },
          { field: "user_id", headerName: "User ID", width: 150 },
          { field: "title", headerName: "Title", width: 200 },
          {
            field: "is_active",
            headerName: "Is Active",
            width: 100,
            type: "boolean",
          },
          {
            field: "totalMessages",
            headerName: "Total Messages",
            width: 150,
            type: "number",
          },
          {
            field: "last_assistant_id",
            headerName: "Last Assistant ID",
            width: 150,
          },
          {
            field: "variable_costs",
            headerName: "Variable Costs",
            width: 150,
            valueGetter: (params) => JSON.stringify(params.value),
          },
          {
            field: "context",
            headerName: "Context",
            width: 150,
            valueGetter: (params) => JSON.stringify(params.value),
          },
          {
            field: "last_message_timestamp",
            headerName: "Last Message Timestamp",
            width: 200,
          },
          {
            field: "created_at",
            headerName: "Created At",
            width: 200,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
          },
          {
            field: "updated_at",
            headerName: "Updated At",
            width: 200,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
          },
        ];
      case "bikeIdentificationJobs":
      case "bikeValuationJobs":
        return [
          { field: "id", headerName: "ID", width: 100 },
          { field: "state", headerName: "State", width: 120 },
          {
            field: "progress",
            headerName: "Progress",
            width: 120,
            type: "number",
            valueGetter: (params) => `${params.value}%`,
          },
          {
            field: "completed_at",
            headerName: "Completed At",
            width: 300,
            valueGetter: (params) => new Date(params.value).toLocaleString(),
          },
        ];
      default:
        return [];
    }
  };

  const handleRowClick = (params) => {
    if (activeSection === "sheldonThreads") {
      setSelectedThread(params.row);
    } else if (
      activeSection === "bikeIdentificationJobs" ||
      activeSection === "bikeValuationJobs"
    ) {
      setSelectedJob(params.row);
    }
  };

  const handleBack = () => {
    setSelectedThread(null);
  };

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      {!selectedJob && !selectedThread ? (
        <>
          <h1 className="text-4xl font-extrabold mb-6 capitalize">
            {activeSection.replace(/([A-Z])/g, " $1").trim()}
          </h1>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              onRowClick={handleRowClick}
              sortModel={[
                {
                  field: "createdAt",
                  sort: "desc",
                },
              ]}
            />
          </div>
        </>
      ) : selectedThread ? (
        <ThreadMessages
          thread={selectedThread}
          token={token}
          onBack={handleBack}
        />
      ) : selectedJob ? (
        <JobDetails job={selectedJob} token={token} onBack={handleBack} />
      ) : null}
    </div>
  );
};

export default DataTable;
