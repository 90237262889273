import React, { useState, useEffect } from "react";
import { useStore } from "../../store";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

export default function Members({ company }) {
  const navigate = useNavigate();
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setToast = useStore((state) => state.setToast);
  const [members, setMembers] = useState(company.members);

  const handleAddMember = async () => {
    // Implement the logic to add a new member
    // You can use a modal or a separate form component for this
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "role", headerName: "Role", width: 150 },
    { field: "added", headerName: "Added", width: 200 },
  ];

  const rows = members.map((member) => ({
    id: member.id,
    name: member.user.name,
    email: member.user.email,
    role: member.role,
    added: member.created_at,
  }));

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-6 items-center">
          <button onClick={() => navigate("/profile?screen=company")}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <h1 className="text-4xl font-extrabold">Members</h1>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
            type="button"
            onClick={handleAddMember}
          >
            <FontAwesomeIcon icon={faUserPlus} className="mr-4" />
            Add Member
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-4 p-8">
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
      </div>
    </div>
  );
}
