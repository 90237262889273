import React, { useState, useRef } from "react";
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { getEndpointLabel } from "./utils";

export const FilterComponents = ({
  filters,
  filterOptions,
  apiKeys,
  handleFilterChange,
  handleDateTimeChange,
  resetFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const FilterSelect = ({ name, label, options, getOptionLabel }) => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        name={name}
        value={filters[name]}
        onChange={handleFilterChange}
        label={label}
        renderValue={(selected) => selected.map(getOptionLabel).join(", ")}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={filters[name].indexOf(option.value) > -1} />
            <ListItemText primary={getOptionLabel(option.value)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        color="inherit"
        aria-label="filter list"
      >
        <FontAwesomeIcon icon={faFilter} />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{ width: 300 }}
          role="presentation"
          ref={drawerRef}
          onClick={(event) => event.stopPropagation()}
        >
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">Filters</Typography>
              <IconButton onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <FilterSelect
              name="endpoints"
              label="Endpoints"
              options={filterOptions.endpoints.map((e) => ({ value: e }))}
              getOptionLabel={getEndpointLabel}
            />
            <FilterSelect
              name="origins"
              label="Origins"
              options={filterOptions.origins.map((o) => ({ value: o }))}
              getOptionLabel={(o) => o}
            />
            <FilterSelect
              name="companies"
              label="Companies"
              options={apiKeys.map((k) => ({ value: k.company?.id }))}
              getOptionLabel={(id) =>
                apiKeys.find((k) => k.company?.id === id)?.company?.name ||
                "Unknown Company"
              }
            />
            <FilterSelect
              name="jobStatuses"
              label="Job Statuses"
              options={filterOptions.jobStatuses.map((s) => ({ value: s }))}
              getOptionLabel={(s) => s}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Date Range</InputLabel>
              <Select
                name="dateRange"
                value={filters.dateRange}
                onChange={handleFilterChange}
                label="Date Range"
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="1h">Last Hour</MenuItem>
                <MenuItem value="24h">Last 24 Hours</MenuItem>
                <MenuItem value="7d">Last 7 Days</MenuItem>
                <MenuItem value="30d">Last 30 Days</MenuItem>
                <MenuItem value="custom">Custom Range</MenuItem>
              </Select>
            </FormControl>
            {filters.dateRange === "custom" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Date and Time"
                  value={
                    filters.startDateTime ? dayjs(filters.startDateTime) : null
                  }
                  onChange={(dateTime) =>
                    handleDateTimeChange("startDateTime", dateTime)
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mb: 2 }} />
                  )}
                />
                <DateTimePicker
                  label="End Date and Time"
                  value={
                    filters.endDateTime ? dayjs(filters.endDateTime) : null
                  }
                  onChange={(dateTime) =>
                    handleDateTimeChange("endDateTime", dateTime)
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mb: 2 }} />
                  )}
                />
              </LocalizationProvider>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
