import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full flex items-center justify-center p-8">
      <div className="w-2/3">
        <h1 className="text-xl font-semibold mb-4">Privacy Policy</h1>

        <h2 className="text-lg font-semibold mb-2">1. Introduction</h2>
        <p className="mb-4">
          Welcome to BikeWise's Privacy Policy. We value your privacy and strive
          to protect your personal information. This policy outlines how we
          collect, use, and protect your data when you visit
          https://app.bikewise.ai (the "Site").
        </p>

        <h2 className="text-lg font-semibold mb-2">
          2. Information Collection
        </h2>
        <p className="mb-4">
          We may collect various types of information, including but not limited
          to:
          <br /> a) Personal data like name, email address.
          <br /> b) Usage data like IP addresses, browser type, and other
          standard web log data.
        </p>

        <h2 className="text-lg font-semibold mb-2">3. Use of Information</h2>
        <p className="mb-4">
          Information collected is used for:
          <br /> a) Providing and improving our services.
          <br /> b) Responding to user inquiries and feedback.
          <br /> c) Enforcing our terms and policies.
        </p>

        <h2 className="text-lg font-semibold mb-2">4. Data Protection</h2>
        <p className="mb-4">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          internet is 100% secure.
        </p>

        <h2 className="text-lg font-semibold mb-2">5. Cookies</h2>
        <p className="mb-4">
          The Site may use cookies to enhance user experience. Users can opt-out
          of cookies through their browser settings.
        </p>

        <h2 className="text-lg font-semibold mb-2">6. Third-Party Services</h2>
        <p className="mb-4">
          We may use third-party services to improve our Site. These third-party
          sites have their own privacy policies, and we have no responsibility
          or liability for their content or activities.
        </p>

        <h2 className="text-lg font-semibold mb-2">7. Children's Privacy</h2>
        <p className="mb-4">
          Our Site is not intended for use by individuals under the age of 13.
          We do not knowingly collect personal information from children under
          13.
        </p>

        <h2 className="text-lg font-semibold mb-2">
          8. Changes to This Policy
        </h2>
        <p className="mb-4">
          We may update our Privacy Policy periodically. We will notify users of
          any changes by posting the updated policy on this page.
        </p>

        <h2 className="text-lg font-semibold mb-2">9. Contact Information</h2>
        <p className="mb-4">
          If you have questions or concerns about this Privacy Policy, please
          contact us at hello@bikewise.ai.
        </p>

        <p className="">
          Last updated: <span className="font-semibold">10/28/2023</span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
