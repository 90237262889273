import React from "react";
import AdminLayout from "../components/admin";

const AdminPage = () => {
  return (
    <div className="bg-gradient-to-br from-bikewise-green to-bikewise-green min-h-[100vh]">
      <div
        className="h-screen min-h-screen min-w-screen w-screen bg-cover p-8 bg-center"
        style={{ backgroundImage: `url("/images/backgroundPattern.svg")` }}
      >
        <AdminLayout />
      </div>
    </div>
  );
};

export default AdminPage;
