import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";

const CustomSelect = ({
  options,
  value,
  onChange,
  settings,
  disabled,
  question,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [position, setPosition] = useState("bottom");
  const selectRef = useRef(null);
  const optionsRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && selectRef.current && optionsRef.current) {
      const selectRect = selectRef.current.getBoundingClientRect();
      const optionsRect = optionsRef.current.getBoundingClientRect();
      const spaceAbove = selectRect.top;
      const spaceBelow = window.innerHeight - selectRect.bottom;

      if (spaceAbove > spaceBelow && spaceAbove >= optionsRect.height) {
        setPosition("top");
      } else {
        setPosition("bottom");
      }
    }
  }, [isOpen]);

  const filteredOptions = options.filter(
    (option) =>
      option === "Other" ||
      option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={selectRef}>
      <div
        style={{
          backgroundColor: settings.backgroundColor,
          color: settings.textColor,
          border: `1px solid ${settings.primaryColor}`,
        }}
        className={`w-full p-2 rounded transition-colors duration-200 flex justify-between items-center ${
          disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <span>{value || "Select an option"}</span>
        <span className="ml-2">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>
      {isOpen && !disabled && (
        <div
          ref={optionsRef}
          className={`absolute z-10 w-full rounded shadow-lg ${
            position === "top" ? "bottom-full mb-1" : "top-full mt-1"
          }`}
          style={{ backgroundColor: "white" }}
        >
          <div className="p-2 border-b bg-bikewise-green text-white font-bold">
            {question}
          </div>
          <div className="p-2 border-b">
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 pr-8 rounded border"
                placeholder="Search..."
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute right-3 top-3 text-gray-400"
              />
            </div>
          </div>
          <div className="max-h-[20vh] overflow-y-auto">
            {filteredOptions.map((option) => (
              <div
                key={option}
                className="p-2 cursor-pointer hover:bg-bikewise-darkGreen hover:text-white"
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                  setSearchTerm("");
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
