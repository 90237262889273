import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useStore } from "../store";

const BikeModelList = ({
  models,
  token,
  fetchBikeModelById,
  onSelectModel,
}) => {
  const getTotalVariants = () => {
    return models.reduce((total, model) => {
      return total + model.bike_model_variants.length;
    }, 0);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 130,
      valueGetter: (params) => params.row.manufacturer.name,
      sortable: true,
      filterable: true,
    },
    {
      field: "name",
      headerName: "Model Name",
      width: 130,
      sortable: true,
      filterable: true,
    },
    {
      field: "year",
      headerName: "Year",
      width: 90,
      type: "number",
      sortable: true,
      filterable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 110,
      sortable: true,
      filterable: true,
    },
    {
      field: "is_electric",
      headerName: "Electric",
      width: 110,
      type: "boolean",
      sortable: true,
      filterable: true,
    },
    {
      field: "variants",
      headerName: "Variants",
      width: 110,
      type: "number",
      valueGetter: (params) => params.row.bike_model_variants.length,
      sortable: true,
    },
    {
      field: "totalSizes",
      headerName: "Total Variants w Sizes",
      width: 110,
      type: "number",
      valueGetter: (params) => {
        return params.row.bike_model_variants.reduce((total, variant) => {
          return variant.sizes.length * params.row.bike_model_variants.length;
        }, 0);
      },
      sortable: true,
    },
    {
      field: "colorSchemeCount",
      headerName: "Color Schemes",
      width: 130,
      type: "number",
      valueGetter: (params) => {
        return params.row.bike_model_variants.reduce((total, variant) => {
          return (
            total + (variant.color_schemes ? variant.color_schemes.length : 0)
          );
        }, 0);
      },
      sortable: true,
    },
  ];

  const handleRowClick = async (token, row) => {
    try {
      const selectedModel = await fetchBikeModelById(token, row.id);
      onSelectModel(selectedModel);
    } catch (error) {
      console.error("Error fetching bike model details:", error);
    }
  };

  return (
    <div style={{ height: "95vh", width: "100%" }}>
      <Typography variant="p" className="mb-4">
        Total Bike Model Variants with Sizes: {getTotalVariants()}
      </Typography>
      <DataGrid
        rows={models}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={(params) => handleRowClick(token, params.row)}
      />
    </div>
  );
};

const BikeModelDetail = ({ model, depth = 0 }) => {
  console.log(model);
  const backgroundColor = `rgba(0, 0, 0, ${Math.min(0.1 + depth * 0.1, 0.9)})`;
  const textColor = depth > 4 ? "white" : "inherit";

  return (
    <Paper className="p-4" style={{ backgroundColor, color: textColor }}>
      <Typography variant="h4" className="mb-4">
        {model.manufacturer?.name || "Unknown Manufacturer"}{" "}
        {model.name || "Unnamed Model"} (ID: {model.id})
      </Typography>
      <Typography variant="subtitle1">
        Year: {model.year || "Unknown"}
      </Typography>
      <Typography variant="subtitle1">
        Type: {model.type || "Unknown"}
      </Typography>
      <Typography variant="subtitle1">
        Electric: {model.is_electric ? "Yes" : "No"}
      </Typography>

      <Typography variant="h5" className="my-4">
        Variants
      </Typography>
      {(model.bike_model_variants || []).map((variant) => (
        <Paper
          key={variant.id}
          elevation={3}
          className="p-4 mb-4"
          style={{
            backgroundColor: `rgba(0, 0, 0, ${Math.min(
              0.1 + (depth + 1) * 0.1,
              0.9
            )})`,
            color: depth > 3 ? "white" : "inherit",
          }}
        >
          <Typography variant="h6" className="mb-2">
            Variant: {variant.name || "Unnamed Variant"} (ID: {variant.id})
          </Typography>
          <Accordion>
            <AccordionSummary>
              <Typography>Color Schemes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {variant.color_schemes && variant.color_schemes.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Color</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {variant.color_schemes.map((scheme) => (
                        <TableRow key={scheme.id}>
                          <TableCell>{scheme.id}</TableCell>
                          <TableCell>{scheme.name || "Unnamed"}</TableCell>
                          <TableCell>
                            <div
                              className="w-8 h-8 rounded"
                              style={{
                                backgroundColor:
                                  scheme.hex_codes && scheme.hex_codes[0]
                                    ? scheme.hex_codes[0]
                                    : "#CCCCCC",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No color schemes available</Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Sizes and Geometries</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {variant.sizes && variant.sizes.length > 0 ? (
                variant.sizes.map((sizeVariant) => (
                  <div key={sizeVariant.id} className="mb-3">
                    <Typography variant="body1" className="font-semibold">
                      Size: {sizeVariant.size?.name || "Unnamed"}
                      {sizeVariant.size?.value && ` (${sizeVariant.size.value}`}
                      {sizeVariant.size?.unit && ` ${sizeVariant.size.unit})`}
                      (ID: {sizeVariant.id})
                    </Typography>
                    {sizeVariant.geometries &&
                    sizeVariant.geometries.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Measurement</TableCell>
                              <TableCell>Value</TableCell>
                              <TableCell>Unit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sizeVariant.geometries.map((geometry) => (
                              <TableRow key={geometry.id}>
                                <TableCell>{geometry.id}</TableCell>
                                <TableCell>
                                  {geometry.geometry.measurement || "Unknown"}
                                </TableCell>
                                <TableCell>
                                  {geometry.geometry.value !== null
                                    ? geometry.geometry.value
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  {geometry.geometry.unit || "N/A"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography>
                        No geometry data available for this size
                      </Typography>
                    )}
                  </div>
                ))
              ) : (
                <Typography>No size data available</Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Parts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {variant.sizes &&
              variant.sizes[0]?.parts &&
              variant.sizes[0].parts.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Part</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Specifications</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {variant.sizes[0].parts.map((partRelation) => (
                        <TableRow key={partRelation.id}>
                          <TableCell>
                            {partRelation.part?.id || "N/A"}
                          </TableCell>
                          <TableCell>
                            {partRelation.part?.manufacturer?.name || "Unknown"}{" "}
                            {partRelation.part?.model || "Unnamed"}
                          </TableCell>
                          <TableCell>
                            {partRelation.part?.partType?.name || "Unknown"}
                          </TableCell>
                          <TableCell>
                            {partRelation.part?.specifications &&
                            partRelation.part.specifications.length > 0
                              ? partRelation.part.specifications.map(
                                  (spec, index) => (
                                    <React.Fragment key={spec.id}>
                                      {index > 0 && <br />}
                                      ID: {spec.id}, {spec.type || "Unknown"}:{" "}
                                      {spec.value || "N/A"} {spec.units || ""}
                                    </React.Fragment>
                                  )
                                )
                              : "No specifications available"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No parts data available</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
      ))}
    </Paper>
  );
};

const createAxiosInstance = (token) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const fetchBikeModels = async (token) => {
  try {
    const axiosInstance = createAxiosInstance(token);
    const response = await axiosInstance.get("/admin/bike-models");
    return response.data;
  } catch (error) {
    console.error("Error fetching bike models:", error);
    throw error;
  }
};

const fetchBikeModelById = async (token, modelId) => {
  try {
    const axiosInstance = createAxiosInstance(token);
    const response = await axiosInstance.get(`/admin/bike-models/${modelId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching bike model by ID:", error);
    throw error;
  }
};

const BikeModelDashboard = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useStore((state) => state.token);

  useEffect(() => {
    const getBikeModels = async () => {
      try {
        const data = await fetchBikeModels(token);
        setModels(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bike models:", error);
        setLoading(false);
      }
    };

    if (token) {
      getBikeModels();
    }
  }, [token]);

  if (loading) {
    return (
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      className="p-4"
      sx={{
        height: "100vh",
        overflowY: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <BikeModelList
            models={models}
            token={token}
            fetchBikeModelById={fetchBikeModelById}
            onSelectModel={setSelectedModel}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedModel ? (
            <BikeModelDetail model={selectedModel} />
          ) : (
            <Typography variant="body1">
              Select a model to view details
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BikeModelDashboard;
