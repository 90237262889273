import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";

const SignOut = () => {
  const clearAuth = useStore((state) => state.clearAuth);
  const navigate = useNavigate();

  useEffect(() => {
    clearAuth();
    navigate("/"); // Redirect to home page after signing out
  }, [clearAuth, navigate]);

  return null; // This component doesn't render anything
};

export default SignOut;
