import React, { useState } from "react";
import { useStore } from "../../store";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function PartsBin() {
  const [addPartOpen, setAddPartOpen] = useState(false);

  return (
    <div className="flex flex-col text-black">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-extrabold">Parts Bin</h1>
        <div className="flex items-center justify-between">
          <button
            className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
            type="button"
            onClick={() => setAddPartOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-4" />
            Add New Part
          </button>
        </div>
      </div>
    </div>
  );
}
