import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const ServiceRecordTaskList = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [serviceRecord, setServiceRecord] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);

  useEffect(() => {
    fetchServiceRecord();
  }, []);

  const fetchServiceRecord = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/service-record/${id}`
      );
      setServiceRecord(response.data);
    } catch (error) {
      console.error("Error fetching service record:", error);
    }
  };

  const handleTaskToggle = (taskId) => {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter((id) => id !== taskId));
    } else {
      setSelectedTasks([...selectedTasks, taskId]);
    }
  };

  const handleSave = async () => {
    console.log(serviceRecord.service_work_plan.id);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/service-record/${serviceRecord.service_work_plan.id}/tasks`,
        {
          taskIds: selectedTasks,
        }
      );
      window.location.href = "https://wa.me/15556136658";
    } catch (error) {
      console.error("Error saving tasks:", error);
    }
  };

  if (!serviceRecord) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  const { service_work_plan } = serviceRecord;
  let workPlanTemplate = null;
  let tasks = [];

  if (service_work_plan) {
    workPlanTemplate = service_work_plan.work_plan_template;
    if (workPlanTemplate) {
      tasks = workPlanTemplate.tasks;
    }
  }

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-gray-700 mb-6">
        Service Record Task List
      </h2>
      {tasks.length > 0 ? (
        <>
          <ul className="space-y-4">
            {tasks.map((task) => (
              <li key={task.id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedTasks.includes(task.id)}
                  onChange={() => handleTaskToggle(task.id)}
                  className="mr-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label className="text-gray-700">{task.name}</label>
              </li>
            ))}
          </ul>
          <button
            onClick={handleSave}
            className="mt-6 px-4 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </>
      ) : (
        <p className="text-gray-600">
          No tasks available for this service record.
        </p>
      )}
    </div>
  );
};

export default ServiceRecordTaskList;
