import React, { useEffect, useRef, useState } from "react";
import "./Dino.css";

function Dino() {
  const dinoRef = useRef();
  const cactusRef = useRef();
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);

  const jump = (event) => {
    if (event.code === "Space") {
      if (!gameStarted) {
        setGameStarted(true);
        setScore(0);
      }

      if (!!dinoRef.current && dinoRef.current.classList != "jump") {
        dinoRef.current.classList.add("jump");
        setTimeout(function () {
          dinoRef.current.classList.remove("jump");
        }, 300);
      }
    }
  };

  useEffect(() => {
    if (gameStarted) {
      const isAlive = setInterval(function () {
        // get current dino Y position
        const dinoTop = parseInt(
          getComputedStyle(dinoRef.current).getPropertyValue("top")
        );

        // get current cactus X position
        let cactusLeft = parseInt(
          getComputedStyle(cactusRef.current).getPropertyValue("left")
        );

        // detect collision
        if (cactusLeft < 40 && cactusLeft > 0 && dinoTop >= 140) {
          // collision
          alert("Game Over!");
          setGameStarted(false); // Reset game state
        } else {
          setScore((prevScore) => prevScore + 1); // Update using functional update
        }
      }, 10);

      return () => clearInterval(isAlive);
    }
  }, [gameStarted]); // Removed 'score' from dependency array

  useEffect(() => {
    document.addEventListener("keydown", jump);
    return () => document.removeEventListener("keydown", jump);
  }, []);

  return (
    <div className="game">
      {gameStarted ? `Score : ${score}` : "Press Space to start"}
      <div id="dino" ref={dinoRef}></div>
      <div id="cactus" ref={cactusRef}></div>
    </div>
  );
}

export default Dino;
