import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import BikeDetails from "./bikeDetails.js";
import axios from "axios";
import { useStore } from "../../../store";

const AdminBikeList = ({ company }) => {
  const [bikes, setBikes] = useState([]);

  const token = useStore((state) => state.token);

  useEffect(() => {
    fetchBikes();
  }, []);

  const fetchBikes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/company/${company.id}/bikes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBikes(response.data);
    } catch (error) {
      console.error("Error fetching bikes:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      {bikes.map((bike) => (
        <BikeDetails key={bike.id} bike={bike} />
      ))}
    </Grid>
  );
};

export default AdminBikeList;
