import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";
import Sidebar from "./sidebar";
import DataTable from "./dataTable";

const AdminDashboard = () => {
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("allUsers");

  useEffect(() => {
    if (!userInfo || !userInfo.roles.includes("admin")) {
      navigate("/profile");
    }
  }, [userInfo, navigate]);

  return (
    <div className="h-full">
      <div className="flex h-full">
        <Sidebar
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        <div className="flex-1 p-8 h-full">
          <DataTable activeSection={activeSection} token={token} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
