import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { camelToTitle } from "../../util/functions";

const SinglePartVariant = () => {
  const { partVariantId } = useParams();
  const [partVariant, setPartVariant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartVariant = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/part-variant/${partVariantId}`
        );
        setPartVariant(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (partVariantId) {
      fetchPartVariant();
    }
  }, [partVariantId]);

  const handleUpdatePartVariant = () => {
    console.log("Update part variant with ID:", partVariantId);
    // Implement update part variant functionality here
  };

  const renderSpecs = (specs, isSubSpec = false) => {
    return (
      <ul className={isSubSpec ? "pl-4" : ""}>
        {Object.entries(specs).map(([key, value]) => {
          if (typeof value === "object") {
            return (
              <li key={key}>
                <strong>{camelToTitle(key)}:</strong>
                {renderSpecs(value, true)}
              </li>
            );
          }
          return (
            <li key={key}>
              {camelToTitle(key)}: {value}
            </li>
          );
        })}
      </ul>
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!partVariant) return <div>No part variant found.</div>;

  return (
    <div className="bg-gradient-to-br from-bikewise-green to-bikewise-green min-h-[100vh]">
      <div
        className="min-h-screen min-w-screen bg-cover p-8 bg-center"
        style={{ backgroundImage: `url("/images/backgroundPattern.svg")` }}
      >
        <div className="flex flex-col md:flex-row mx-auto rounded-xl bg-white p-8 gap-16">
          <div className="part-variant-details w-full">
            {partVariant.status}
            <h1>
              {partVariant.part.partType?.name} - {partVariant.part.name}
            </h1>
            <div className="">
              <div className="flex gap-4">
                <div>
                  <h3>Specifications</h3>
                  {renderSpecs(partVariant.specs)}
                </div>
                <div className="col-span-2">
                  <button
                    className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-lg"
                    onClick={handleUpdatePartVariant}
                  >
                    Update Part Variant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePartVariant;
