import React, { useState, useMemo, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const INTERVALS = {
  MINUTELY: "minutely",
  HOURLY: "hourly",
  DAILY: "daily",
  WEEKLY: "weekly",
};

const getTimeRangeAndStep = (interval) => {
  const now = new Date();
  switch (interval) {
    case INTERVALS.MINUTELY:
      return {
        start: new Date(now - 60 * 60 * 1000),
        step: 60 * 1000,
        unit: "minute",
      };
    case INTERVALS.HOURLY:
      return {
        start: new Date(now - 24 * 60 * 60 * 1000),
        step: 60 * 60 * 1000,
        unit: "hour",
      };
    case INTERVALS.DAILY:
      return {
        start: new Date(now - 30 * 24 * 60 * 60 * 1000),
        step: 24 * 60 * 60 * 1000,
        unit: "day",
      };
    case INTERVALS.WEEKLY:
      return {
        start: new Date(now - 12 * 7 * 24 * 60 * 60 * 1000),
        step: 7 * 24 * 60 * 60 * 1000,
        unit: "week",
      };
    default:
      throw new Error("Invalid interval");
  }
};

const getLocationData = async (ip) => {
  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await response.json();

    let location = data.city;
    if (data.region && data.region !== data.city) {
      location += `, ${data.region}`;
    }
    location += `, ${data.country_name}`;

    return location;
  } catch (error) {
    console.error("Error fetching location data:", error);
    return "Unknown";
  }
};

const PowerUsersChart = ({ requests, onIpAddressClick }) => {
  const [chartData, setChartData] = useState(null);
  const [topUserCount, setTopUserCount] = useState(10);

  useEffect(() => {
    const fetchLocationData = async () => {
      if (!requests || requests.length === 0) return;

      const userActions = requests.reduce((acc, req) => {
        if (!req.ip_address) return acc;

        if (!acc[req.ip_address]) {
          acc[req.ip_address] = {};
        }

        if (!acc[req.ip_address][req.endpoint]) {
          acc[req.ip_address][req.endpoint] = 0;
        }

        acc[req.ip_address][req.endpoint]++;
        return acc;
      }, {});

      const sortedUsers = Object.entries(userActions)
        .sort(
          (a, b) =>
            Object.values(b[1]).reduce((sum, count) => sum + count, 0) -
            Object.values(a[1]).reduce((sum, count) => sum + count, 0)
        )
        .slice(0, topUserCount);

      const locationPromises = sortedUsers.map(async ([ip]) => {
        const location = await getLocationData(ip);
        return `${location}\n${ip}`;
      });

      const labels = await Promise.all(locationPromises);
      const endpoints = [...new Set(requests.map((req) => req.endpoint))];

      const datasets = endpoints.map((endpoint, index) => ({
        label: endpoint,
        data: sortedUsers.map(([, counts]) => counts[endpoint] || 0),
        backgroundColor: `hsl(${(index * 360) / endpoints.length}, 70%, 50%)`,
      }));

      setChartData({ labels, datasets });
    };

    fetchLocationData();
  }, [requests, topUserCount]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "IP Address",
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Actions",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Top ${topUserCount} Power Users by IP Address and Endpoint`,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const dataIndex = elements[0].index;
        const ipAddress = chartData.labels[dataIndex].split("\n")[1];
        onIpAddressClick(ipAddress);
      }
    },
  };

  return (
    <Paper className="p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <Typography variant="h6">Power Users</Typography>
        <FormControl variant="outlined" className="min-w-[120px]">
          <InputLabel id="top-user-count-label">Top Users</InputLabel>
          <Select
            labelId="top-user-count-label"
            value={topUserCount}
            onChange={(e) => setTopUserCount(e.target.value)}
            label="Top Users"
          >
            <MenuItem value={5}>Top 5</MenuItem>
            <MenuItem value={10}>Top 10</MenuItem>
            <MenuItem value={25}>Top 25</MenuItem>
            <MenuItem value={50}>Top 50</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="h-96">
        {chartData ? (
          <Bar data={chartData} options={options} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>
    </Paper>
  );
};

export const ChartComponents = ({ requests, onIpAddressClick }) => {
  const [trendInterval, setTrendInterval] = useState(INTERVALS.HOURLY);

  const handleIntervalChange = (event) => {
    setTrendInterval(event.target.value);
  };

  const trendChartData = useMemo(() => {
    if (!requests || requests.length === 0) return null;

    const { start, step, unit } = getTimeRangeAndStep(trendInterval);
    const end = new Date();
    const buckets = {};

    // Initialize buckets
    for (
      let time = new Date(start);
      time <= end;
      time = new Date(time.getTime() + step)
    ) {
      buckets[time.getTime()] = 0;
    }

    // Ensure the current interval is included
    const lastBucketKey =
      Math.floor((end - start) / step) * step + start.getTime();
    if (!buckets[lastBucketKey]) {
      buckets[lastBucketKey] = 0;
    }

    // Count requests
    requests.forEach((req) => {
      const reqTime = new Date(req.timestamp);
      if (reqTime >= start && reqTime <= end) {
        const bucketKey =
          Math.floor((reqTime - start) / step) * step + start.getTime();
        buckets[bucketKey]++;
      }
    });

    const sortedBuckets = Object.entries(buckets).sort(([a], [b]) => a - b);

    return {
      labels: sortedBuckets.map(([time]) => new Date(parseInt(time))),
      datasets: [
        {
          label: `Requests per ${unit}`,
          data: sortedBuckets.map(([, count]) => count),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };
  }, [requests, trendInterval]);

  const endpointChartData = useMemo(() => {
    if (!requests || requests.length === 0) return null;

    const endpointCounts = requests.reduce((acc, req) => {
      acc[req.endpoint] = (acc[req.endpoint] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(endpointCounts),
      datasets: [
        {
          label: "Requests per Endpoint",
          data: Object.values(endpointCounts),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
      ],
    };
  }, [requests]);

  const statusChartData = useMemo(() => {
    if (!requests || requests.length === 0) return null;

    const statusCounts = requests.reduce((acc, req) => {
      acc[req.job_status] = (acc[req.job_status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(statusCounts),
      datasets: [
        {
          data: Object.values(statusCounts),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
        },
      ],
    };
  }, [requests]);

  const trendChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: getTimeRangeAndStep(trendInterval).unit,
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Requests",
        },
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col space-y-4">
      <Paper className="p-4">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h6">Request Trend</Typography>
          <FormControl variant="outlined" className="min-w-[120px]">
            <InputLabel id="trend-interval-label">Interval</InputLabel>
            <Select
              labelId="trend-interval-label"
              value={trendInterval}
              onChange={handleIntervalChange}
              label="Interval"
            >
              <MenuItem value={INTERVALS.MINUTELY}>Minute</MenuItem>
              <MenuItem value={INTERVALS.HOURLY}>Hour</MenuItem>
              <MenuItem value={INTERVALS.DAILY}>Day</MenuItem>
              <MenuItem value={INTERVALS.WEEKLY}>Week</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="h-72">
          {trendChartData ? (
            <Line data={trendChartData} options={trendChartOptions} />
          ) : (
            <Typography>Loading...</Typography>
          )}
        </div>
      </Paper>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Paper className="p-4">
          <Typography variant="h6" className="mb-4">
            Requests per Endpoint
          </Typography>
          <div className="h-72">
            {endpointChartData ? (
              <Bar data={endpointChartData} options={barChartOptions} />
            ) : (
              <Typography>Loading...</Typography>
            )}
          </div>
        </Paper>
        <Paper className="p-4">
          <Typography variant="h6" className="mb-4">
            Job Status Distribution
          </Typography>
          <div className="h-72">
            {statusChartData ? (
              <Pie data={statusChartData} options={pieChartOptions} />
            ) : (
              <Typography>Loading...</Typography>
            )}
          </div>
        </Paper>
      </div>

      <PowerUsersChart
        requests={requests}
        onIpAddressClick={onIpAddressClick}
      />
    </div>
  );
};
