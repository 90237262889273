import React, { useState } from "react";
import { useStore } from "../../store";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSync,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

export default function Settings({ company, userCompanyRole }) {
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setToast = useStore((state) => state.setToast);

  const [apiKey, setApiKey] = useState(company?.api_key?.[0]?.key || "");
  const [showApiKey, setShowApiKey] = useState(false);

  const regenerateApiKey = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/company/${company.id}/regenerate-api-key`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setApiKey(response.data.key);
        setToast({
          type: "success",
          message: "API key regenerated successfully!",
        });
      }
    } catch (error) {
      console.error("Error regenerating API key:", error);
      setToast({
        type: "error",
        message: "Error regenerating API key. Please try again.",
      });
    }
  };

  if (userCompanyRole !== "owner" && userCompanyRole !== "admin") {
    return (
      <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
        <h1 className="text-4xl font-extrabold mb-6">Settings</h1>
        <p>You must be the company owner to access settings.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      <h1 className="text-4xl font-extrabold mb-6">Settings</h1>
      <div className="flex flex-col gap-4">
        <div>
          <label className="block text-sm font-bold mb-2">API Key</label>

          <div className="text-sm font-bold bg-[#FFFAA0] p-2 rounded-lg flex gap-4 items-center mb-4">
            <FontAwesomeIcon icon={faWarning} />
            Do not share your API key with anyone. This key allows access to
            your company's data.
          </div>
          <div className="flex items-center gap-4">
            <div className="relative w-full">
              <input
                type={showApiKey ? "text" : "password"}
                value={apiKey}
                readOnly
                className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
              />
              <button
                onClick={() => setShowApiKey(!showApiKey)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <FontAwesomeIcon icon={showApiKey ? faEyeSlash : faEye} />
              </button>
            </div>
            <button
              onClick={regenerateApiKey}
              className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
            >
              <FontAwesomeIcon icon={faSync} className="mr-2" />
              Regenerate
            </button>
          </div>
        </div>
        {company?.api_key?.[0]?.created_at && (
          <div>
            <label className="block text-sm font-bold mb-2">
              API Key Created At
            </label>
            <p className="text-gray-700">
              {new Date(company.api_key[0].created_at).toLocaleString()}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
