import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import axios from "axios";

import { useStore } from "../../../store";

const WorkPlanTemplateAdmin = ({ company }) => {
  const [workPlanTemplates, setWorkPlanTemplates] = useState([]);
  const [selectedWorkPlanTemplate, setSelectedWorkPlanTemplate] =
    useState(null);
  const [editingTask, setEditingTask] = useState(null);

  const token = useStore((state) => state.token);

  useEffect(() => {
    fetchWorkPlanTemplates();
  }, []);

  const fetchWorkPlanTemplates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/company/${company.id}/work-plan-templates`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkPlanTemplates(response.data);
    } catch (error) {
      console.error("Error fetching work plan templates:", error);
    }
  };

  const handleSelectWorkPlanTemplate = (template) => {
    setSelectedWorkPlanTemplate(template);
  };

  const handleCreateWorkPlanTemplate = () => {
    // Handle creating a new work plan template
    // Navigate to a form or modal to input the details of the new work plan template
  };

  const handleAddTask = () => {
    setEditingTask({
      id: "",
      name: "",
      description: "",
      estimated_duration: "",
      estimated_difficulty: "",
      estimated_cost: "",
      category: "",
      type: "",
    });
  };

  const handleEditTask = (taskId) => {
    const task = selectedWorkPlanTemplate.tasks.find(
      (task) => task.id === taskId
    );
    setEditingTask(task);
  };

  const handleDeleteTask = async (taskId) => {
    // Delete the task from the API based on the taskId
    // Update the tasks state by removing the deleted task
    // Example: setSelectedWorkPlanTemplate({
    //   ...selectedWorkPlanTemplate,
    //   tasks: selectedWorkPlanTemplate.tasks.filter((task) => task.id !== taskId),
    // });
  };

  const handleSaveTask = async () => {
    if (editingTask.id) {
      // Update the existing task in the API
      // Update the tasks state with the edited task
    } else {
      // Create a new task in the API
      // Add the new task to the tasks state
    }
    setEditingTask(null);
  };

  const handleCancelEdit = () => {
    setEditingTask(null);
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "estimated_duration",
      headerName: "Estimated Duration",
      width: 120,
    },
    {
      field: "estimated_difficulty",
      headerName: "Estimated Difficulty",
      width: 120,
    },
    { field: "estimated_cost", headerName: "Estimated Cost", width: 120 },
    { field: "category", headerName: "Category", width: 120 },
    { field: "type", headerName: "Type", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleEditTask(params.id)}>Edit</Button>
          <Button onClick={() => handleDeleteTask(params.id)}>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div className="flex flex-col text-black bg-white h-[100%] overflow-y-scroll rounded-xl p-4">
      <h2>Work Plan Templates</h2>
      <div>
        {workPlanTemplates.map((template) => (
          <Button
            key={template.id}
            onClick={() => handleSelectWorkPlanTemplate(template)}
          >
            {template.name}
          </Button>
        ))}
        <Button onClick={handleCreateWorkPlanTemplate}>Add New Template</Button>
      </div>
      {selectedWorkPlanTemplate && (
        <div className="w-full">
          <h3>{selectedWorkPlanTemplate.name}</h3>
          <h4>Template Details</h4>
          <p>{selectedWorkPlanTemplate.description}</p>
          <div className="w-full">
            <div>
              <Button onClick={handleAddTask}>Add Task</Button>
              <div style={{ height: "75vh", width: "100%", marginTop: "16px" }}>
                <DataGrid
                  rows={selectedWorkPlanTemplate.tasks}
                  columns={columns}
                  pageSize={25}
                />
              </div>
            </div>
            <div>
              <h4>Statues</h4>
              <ul>
                {selectedWorkPlanTemplate.statuses.map((status) => (
                  <li key={status}>{status}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {editingTask && (
        <div>
          <h3>{editingTask.id ? "Edit Task" : "Add Task"}</h3>
          <TextField
            label="Name"
            value={editingTask.name}
            onChange={(e) =>
              setEditingTask({ ...editingTask, name: e.target.value })
            }
          />
          <TextField
            label="Description"
            value={editingTask.description}
            onChange={(e) =>
              setEditingTask({ ...editingTask, description: e.target.value })
            }
          />
          <TextField
            label="Duration"
            value={editingTask.estimated_duration}
            onChange={(e) =>
              setEditingTask({
                ...editingTask,
                estimated_duration: e.target.value,
              })
            }
          />
          <FormControl>
            <InputLabel>Difficulty</InputLabel>
            <Select
              value={editingTask.estimated_difficulty}
              onChange={(e) =>
                setEditingTask({
                  ...editingTask,
                  estimated_difficulty: e.target.value,
                })
              }
            >
              <MenuItem value="easy">Easy</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="hard">Hard</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Estimated Cost"
            value={editingTask.estimated_cost}
            onChange={(e) =>
              setEditingTask({ ...editingTask, estimated_cost: e.target.value })
            }
          />
          <TextField
            label="Category"
            value={editingTask.category}
            onChange={(e) =>
              setEditingTask({ ...editingTask, category: e.target.value })
            }
          />
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={editingTask.type}
              onChange={(e) =>
                setEditingTask({ ...editingTask, type: e.target.value })
              }
            >
              <MenuItem value="replace">Replace</MenuItem>
              <MenuItem value="repair">Repair</MenuItem>
              <MenuItem value="check/inspect">Check/Inspect</MenuItem>
              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleSaveTask}>Save</Button>
          <Button onClick={handleCancelEdit}>Cancel</Button>
        </div>
      )}
    </div>
  );
};

export default WorkPlanTemplateAdmin;
