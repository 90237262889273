import React from "react";

const JobDetails = ({ job, onBack }) => {
  console.log(job);
  return (
    <div className="flex flex-col h-full overflow-y-scroll">
      <button
        onClick={onBack}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Back to Jobs
      </button>
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Job Details</h2>
        <p>
          <strong>ID:</strong> {job.id}
        </p>
        {job.data && <img className="h-[10vh]" src={job.data?.image_url} />}
        <p>
          <strong>State:</strong> {job.state} - {job.failedReason}
        </p>
        <p>
          <strong>Created At:</strong>{" "}
          {new Date(job.createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Progress:</strong> {job.progress}%
        </p>
        <p>
          <strong>Data:</strong>
        </p>
        <pre className="bg-gray-100 p-2 rounded mt-2 overflow-x-auto">
          {JSON.stringify(job.data, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default JobDetails;
