import React, { useEffect, useState } from "react";
import "./App.css";
import { SafeArea } from "capacitor-plugin-safe-area";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { analytics, logEvent, setUserId } from "./util/firebaseConfig";

import CookieConsentPopup from "./components/common/cookieConsent";

import Banner from "./components/common/banner";
import Footer from "./components/layout/footer";
import Loading from "./components/layout/loading";

import HomePage from "./pages/homePage";
import ProfilePage from "./pages/profilePage";
import AdminPage from "./pages/adminPage";
import AuthCallback from "./pages/authCallback";

//admin

import SingleBikeModel from "./components/admin/singleBikeModel";

//legal
import PrivacyPolicy from "./pages/legal/privacyPolicy";
import CookiePolicy from "./pages/legal/cookiePolicy";
import TermsOfService from "./pages/legal/termsOfService";

import { useStore } from "./store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import SignOut from "./pages/signout";
import NotFoundPage from "./pages/404";
import SinglePartVariant from "./components/admin/singlePartVariant";
import CompanyPage from "./pages/companyPage";
import CompaniesDashboard from "./pages/adminCompaniesTemp";
import ServiceRecordTaskList from "./components/admin/serviceRecords/serviceRecordTasks";
import EmbedWidget from "./widget-legacy/widget";
import BikeWiseWidget from "./widget-legacy/widgetInternal";
import ResponsiveBikeWiseLayout from "./components/profile/bikeWidget";
import RequestDashboard from "./pages/requestDashboard";
import BikeModelDashboard from "./pages/bikeModelDashboard";

const siteId = 3784764;

axios.defaults.headers.common[
  "x-api-authorization"
] = `${process.env.REACT_APP_API_KEY}`;

function App() {
  const isLoading = useStore((state) => state.isLoading);
  const setChromeExtensionInstalled = useStore(
    (state) => state.setChromeExtensionInstalled
  );
  const chromeExtensionInstalled = useStore(
    (state) => state.chromeExtensionInstalled
  );
  const [inset, setInset] = useState(0);

  const initializeAuth = useStore((state) => state.initializeAuth);
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setAuth = useStore((state) => state.setAuth);
  const clearAuth = useStore((state) => state.clearAuth);
  const setToast = useStore((state) => state.setToast);

  const toast = useStore((state) => state.toast);
  const clearToast = useStore((state) => state.clearToast);
  const [slideOut, setSlideOut] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const [bgColor, setBgColor] = useState("bg-bikewise-green");

  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  const USER_INFO_REFRESH_THRESHOLD = 24 * 60 * 60 * 1000; // 24 hours

  const mobile = window.innerWidth < 768;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!userInfo) {
        return;
      }

      const now = new Date().getTime();
      const timeSinceLastUpdate =
        now - new Date(userInfo.authUpdated).getTime();

      const getUser = async () => {
        try {
          // Use await here
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/user",
            {
              id: userInfo.id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data === null || response.data === "null") {
            clearAuth();
            setToast({
              type: "error",
              message: "User not found please sign-in again!",
            });
          } else {
            if (!_.isEqual(response.data, userInfo)) {
              setAuth(token, response.data);
            }
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      };

      if (timeSinceLastUpdate > USER_INFO_REFRESH_THRESHOLD) {
        getUser();
      }
    }, 3600000);

    return () => clearInterval(interval);
  }, [
    userInfo,
    USER_INFO_REFRESH_THRESHOLD,
    token,
    setAuth,
    clearAuth,
    setToast,
  ]);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 3; // Maximum number of attempts
    const intervalTime = 500; // Time in milliseconds between each check

    const checkForExtension = () => {
      if (document.querySelector('meta[name="bikewise-chrome-extension"]')) {
        setChromeExtensionInstalled(true);
        clearInterval(checkInterval); // Clear interval when found
      } else if (attempts >= maxAttempts) {
        setChromeExtensionInstalled(false);
        clearInterval(checkInterval); // Clear interval if max attempts reached
      }
      attempts++;
    };

    const checkInterval = setInterval(checkForExtension, intervalTime);

    return () => clearInterval(checkInterval); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  useEffect(() => {
    // Track page view
    logEvent(analytics, "page_view", {
      page_path: currentUrl,
      user_id: userInfo ? userInfo.id : "anonymous",
      platform: "webapp",
    });
  }, [userInfo, currentUrl]);

  useEffect(() => {
    if (toast) {
      if (toast.message.type === "error") {
        setBgColor("bg-bikewise-red");
      } else if (toast.message.type === "success") {
        setBgColor("bg-bikewise-green");
      } else {
        setBgColor("bg-bikewise-offBlack");
      }
      // First make sure the toast is set to its initial off-screen position
      setSlideIn(false);
      setSlideOut(false);

      // Start a timer to make the toast slide in
      const slideInTimer = setTimeout(() => {
        setSlideIn(true);
      }, 50); // Very short delay to ensure it starts off-screen

      // Start another timer for the slide-out animation
      const slideOutTimer = setTimeout(() => {
        setSlideOut(true);
      }, 4700);

      // Start a final timer to clear the toast after the slide-out animation
      const clearTimer = setTimeout(() => {
        clearToast();
        setSlideOut(false); // Reset states for the next toast
        setSlideIn(false);
      }, 5000);

      // Cleanup function to clear the timers
      return () => {
        clearTimeout(slideInTimer);
        clearTimeout(slideOutTimer);
        clearTimeout(clearTimer);
      };
    }
  }, [toast, clearToast]);

  useEffect(() => {
    // Define an async function
    const getUser = async () => {
      try {
        // Use await here
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/user",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data === null || response.data === "null") {
          clearAuth();
          setToast({
            type: "error",
            message: "User not found please sign-in again!",
          });
        } else {
          const responseDataWithoutAuthUpdated = _.omit(
            response.data,
            "authUpdated"
          );
          const userInfoWithoutAuthUpdated = _.omit(userInfo, "authUpdated");

          if (
            !_.isEqual(
              responseDataWithoutAuthUpdated,
              userInfoWithoutAuthUpdated
            )
          ) {
            setAuth(token, response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    // Call the async function
    if (userInfo && token) {
      getUser();
      setUserId(analytics, userInfo.id);
    } else {
      setUserId(analytics, "anonymous");
    }
  }, [userInfo, token, setAuth, clearAuth]); // Only re-run effect if userInfo or token changes

  useEffect(() => {
    // Define the function to update the statusBarHeight
    const updateStatusBarHeight = async () => {
      const { statusBarHeight } = await SafeArea.getStatusBarHeight();
      setInset(statusBarHeight);
    };

    // Call it initially
    updateStatusBarHeight();

    // Define the listener for safe area changes
    const handleSafeAreaChange = (data) => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-${key}`,
          `${value}px`
        );
      }

      // Update the statusBarHeight when safe area changes
      updateStatusBarHeight();
    };

    // Add the listener
    SafeArea.addListener("safeAreaChanged", handleSafeAreaChange);

    // Return a cleanup function to remove the listener on component unmount
    return () => {
      SafeArea.removeListener("safeAreaChanged", handleSafeAreaChange);
    };
  }, []);

  // canny

  // Function to load the Canny SDK
  const loadCannySDK = (user) => {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          e.type = "text/javascript";
          e.async = true;
          e.id = i;
          e.src = "https://canny.io/sdk.js";
          f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" !== typeof w.Canny) {
        w.Canny = function () {
          (w.Canny.q = w.Canny.q || []).push(arguments);
        };
        if (d.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })(window, document, "canny-jssdk", "script");

    // Once SDK is loaded, identify the user
    if (userInfo && userInfo.email && userInfo.id) {
      window.Canny("identify", {
        appID: "65ac30c31655c276a6eb8023",
        user: {
          email: userInfo.email,
          name: userInfo.name,
          id: userInfo.id,
          avatarURL: userInfo.profile_image_url,
          created: new Date(userInfo.created_at).toISOString(),
        },
      });
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.email) {
      loadCannySDK(userInfo);
    }
  }, [userInfo]);

  return (
    <div className={`font-nunito bg-bikewise-white`}>
      <div>
        <Router>
          {isLoading && <Loading />}
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
            <Route
              path="/legal/terms-of-service"
              element={<TermsOfService />}
            />
            <Route path="/admin" element={<AdminPage />} />
            <Route
              path="/admin/bike/model/:modelId"
              element={<SingleBikeModel />}
            />
            <Route
              path="/admin/part/:partVariantId"
              element={<SinglePartVariant />}
            />
            <Route
              path="/admin/companies/temp"
              element={<CompaniesDashboard />}
            />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/create-sr" element={<ServiceRecordTaskList />} />
            <Route path="/requests" element={<RequestDashboard />} />
            <Route path="/bike-models" element={<BikeModelDashboard />} />
          </Routes>
          {window.location.pathname !== "/" && <Footer />}
          <CookieConsentPopup />
        </Router>
      </div>
      {toast && (
        <div
          className={`max-w-1/4 md:max-w-1/4 z-[100000] fixed top-8 right-8 ${bgColor} text-white font-bold m-4 px-4 py-2 rounded-xl transform transition-transform duration-300 ${
            slideOut
              ? "translate-x-full"
              : slideIn
              ? "-translate-x-0"
              : "translate-x-full"
          }`}
        >
          {toast.message.message}
        </div>
      )}
    </div>
  );
}

export default App;
