import React from "react";
import ProfileLayout from "../components/profile";

const Profilepage = () => {
  return (
    <div>
      <ProfileLayout />
    </div>
  );
};

export default Profilepage;
