import React from "react";
import { Link } from "react-router-dom";
import GitInfo from "react-git-info/macro";
import Moment from "react-moment";

const gitInfo = GitInfo();

export default function Footer() {
  return (
    <div>
      <div className="bg-bikewise-darkGreen p-12">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-between">
            {/* Logo Column */}
            <div className="w-full md:w-1/4">
              <img
                className="w-3/4 md:w-full h-auto mb-2"
                src="/images/logos/fullWhite.svg"
                alt="bikewise-logo-white"
              />
              <div className="text-white text-xs flex flex-col mb-4">
                <span>v0.1.2-{gitInfo.commit.shortHash}</span>
                <Moment format="YYYY/MM/DD">{gitInfo.commit.date}</Moment>
              </div>
            </div>
            <div>
              <h3 className="text-white font-bold mb-4 text-lg">Company</h3>
              <ul className="space-y-2 mb-6">
                <li>
                  <a
                    href="https://bikewise.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:underline"
                  >
                    About
                  </a>
                </li>
                <li>
                  <Link
                    to="/legal/terms-of-service"
                    className="text-white hover:underline"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/legal/privacy-policy"
                    className="text-white hover:underline"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/legal/cookie-policy"
                    className="text-white hover:underline"
                  >
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-white font-bold mb-4 text-lg">Solutions</h3>
              <ul className="space-y-2 mb-6">
                <li>
                  <Link to="/" className="text-white hover:underline">
                    Javascript Widget
                  </Link>
                </li>
                <li>
                  <Link to="/" className="text-white hover:underline">
                    Chrome Extension
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.bikewise.ai/contacte"
                    className="text-white hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shopify App
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.bikewise.ai/contact"
                    className="text-white hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Custom Integration
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-white font-bold mb-4 text-lg">Resources</h3>
              <ul className="space-y-2">
                <li>
                  <a href="/" className="text-white hover:underline">
                    Feedback
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.bikewise.ai/faq"
                    className="text-white hover:underline"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:hello@bikewise.ai"
                    className="text-white hover:underline"
                  >
                    Email
                  </a>
                </li>
                <li>
                  <a
                    href="https://fr.linkedin.com/company/bikewiseai"
                    className="text-white hover:underline"
                  >
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-4 text-white bg-bikewise-offBlack">
        © {new Date().getFullYear()} Bikewise | All rights reserved.
      </div>
    </div>
  );
}
