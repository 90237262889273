import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "./sidebar";
//import Profile from "./profile";
import Settings from "./settings";
import PartsBin from "./partsBin";

import ResponsiveBikeWiseLayout from "./bikeWidget";

import { useStore } from "../../store";
import LoginModal from "../auth/loginModal";
import axios from "axios";

function ProfileLayout() {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const token = useStore((state) => state.token); // Get token from store
  const userInfo = useStore((state) => state.userInfo); // Get userInfo from store
  const setUserInfo = useStore((state) => state.setUserInfo); // Get setUserInfo from store
  const tempBike = useStore((state) => state.tempBike); // Get tempBike from store
  const clearTempBike = useStore((state) => state.clearTempBike);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [screen, setScreen] = useState("profile");

  const chromeExtensionInstalled = useStore(
    (state) => state.chromeExtensionInstalled
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [isTourOpen, setIsTourOpen] = useState(false); // initially open, or set based on a condition

  const steps = [
    {
      selector: '[data-tour="garage"]',
      content: "This is your bike garage where you can manage your bikes.",
    },
    {
      selector: '[data-tour="settings"]',
      content: "Visit settings to customize your account.",
    },
  ];

  if (!chromeExtensionInstalled) {
    steps.push({
      selector: '[data-tour="chrome-extension"]',
      content:
        "Download the Chrome Extension to check part compatibility with BikeWise everywhere!",
    });
  }

  steps.push(
    {
      selector: '[data-tour="help"]',
      content:
        "Need more information? Click here to view the tour again and access other help resources",
    },
    {
      selector: '[data-tour="add-bike"]',
      content: "Ready to get started! Add your first bike here!",
    }
  );

  useEffect(() => {
    if (!isAuthenticated || !userInfo) {
      navigate("/");
    } else if (userInfo && !userInfo.account_verified) {
      setIsTourOpen(false);
    } else if (
      userInfo &&
      userInfo.account_verified &&
      !userInfo.tour_completed
    ) {
      setIsTourOpen(true);
    }
  }, [isAuthenticated, userInfo]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("screen")) {
      setScreen(searchParams.get("screen"));
    } else {
      setScreen("bikes");
    }

    if (searchParams.has("bike")) {
      const bikeId = searchParams.get("bike");
      setSearchParams({ screen: "bikes", bike: bikeId });
    }

    const updateBikeWithUserId = async (bike, userId, token) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/bike/update`,
          {
            id: bike,
            values: { user_id: userId },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.error) {
          clearTempBike();
        }
      } catch (error) {
        clearTempBike();
      }
    };

    if (
      isAuthenticated &&
      tempBike &&
      tempBike !== "null" &&
      userInfo?.id &&
      token
    ) {
      try {
        updateBikeWithUserId(tempBike, userInfo.id, token);
      } catch (error) {
        console.error("Failed to update bike:", error);
      }
    }
  }, [isAuthenticated, tempBike, userInfo, token, clearTempBike, searchParams]);

  const handleCloseTour = async () => {
    setIsTourOpen(false);
    // Update user profile to set tour_completed to true
    if (userInfo && !userInfo.tour_completed) {
      try {
        const updatedUser = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/update`,
          { id: userInfo.id, values: { tour_completed: true } },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (updatedUser.data) {
          setUserInfo(updatedUser.data);
        }
      } catch (error) {
        console.error("Error updating tour_completed:", error);
      }
    }
  };

  /* 

  const resendConfirmation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/resend-verification-email`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        setToast({
          type: "success",
          message: "Verification email sent successfully!",
        });
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setToast({
        type: "error",
        message: "Error resending verification email:",
        error,
      });
    }
  };


*/

  if (!userInfo) {
    return (
      <div className="flex flex-col md:flex-row mx-auto items-center justify-center">
        <div className="flex-grow min-h-screen items-center justify-center">
          <LoginModal
            isOpen={isLoginModalOpen}
            onClose={() => setIsLoginModalOpen(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {/*
       
      {userInfo && !userInfo.account_verified && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white px-8 py-4 rounded-lg shadow-lg w-11/12 md:w-1/3 max-h-[90vh] overflow-y-auto">
            <h1 className="text-3xl md:text-5xl font-black mb-6 text-black text-center">
              Please check your email to verify your account.
            </h1>
            <p className="text-md md:text-lg text-black mb-6">
              If you did not receive an email, please check your spam folder.
            </p>
            <button
              onClick={() => resendConfirmation()}
              className="flex items-center justify-center font-bold py-2 px-4 rounded-xl shadow-md transition duration-150 ease-in-out transform bg-bikewise-yellow text-black hover:scale-105 w-full"
            >
              Re-send Account Verification Email
            </button>
          </div>
        </div>
      )}

      */}

      {/* 

      {isAuthenticated && userInfo && userInfo.account_verified && (
        <Reactour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={handleCloseTour}
        />
      )}
      
      */}

      <div className="relative flex flex-col md:flex-row mx-auto rounded-xl bg-bikewise-smoke">
        <div className="relative w-full md:w-1/4 pl-8 pr-8 md:pr-8">
          <Sidebar setTourOpen={setIsTourOpen} />
        </div>
        <div className="w-full md:w-3/4 max-h-screen">
          {screen === "bikes" ? (
            <ResponsiveBikeWiseLayout />
          ) : screen === "settings" ? (
            <Settings />
          ) : screen === "parts" ? (
            <PartsBin />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileLayout;
