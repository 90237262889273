// CompanyDashboard.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../store";
import axios from "axios";
import Sidebar from "./sidebar";
import CompanyProfile from "./companyProfile";
import Members from "./members";
import CreateCompany from "./createCompany";
import Settings from "./settings";
import WorkPlanTemplateAdmin from "../admin/serviceRecords/workPlanTemplate";
import AdminBikeList from "../admin/serviceRecords/bikes";

const CompanyDashboard = () => {
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [activeSection, setActiveSection] = useState("company-profile");
  const [userCompanyRole, setUserCompanyRole] = useState(null);
  const [companyExists, setCompanyExists] = useState(false);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const companyId = searchParams.get("id");
    console.log(companyId);
    if (companyId) {
      setCompanyId(companyId);
      fetchCompany(companyId);
    } else {
      setShowDashboard(false);
    }
  }, [searchParams]);

  const fetchCompany = async (companyId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const company = response.data;
      setCompanyExists(true);
      setCompany(company);

      let companyRole = company.members.find(
        (member) => member.user_id === userInfo.id
      )?.role;
      setUserCompanyRole(companyRole);

      if (
        companyRole === "owner" ||
        companyRole === "manager" ||
        companyRole === "admin"
      ) {
        setShowDashboard(true);
      } else {
        setShowDashboard(false);
      }
    } catch (error) {
      console.error("Error fetching company:", error);
      setCompanyExists(false);
      setShowDashboard(false);
    }
  };

  if (!companyExists) {
    return <CreateCompany />;
  }

  if (!showDashboard) {
    return <CompanyProfile company={company} isPublic={true} />;
  }

  return (
    <div className="relative flex flex-col md:flex-row mx-auto rounded-xl bg-bikewise-smoke">
      <div className="relative w-full md:w-1/4 pl-8 pr-8 md:pr-0">
        <Sidebar
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          userCompanyRole={userCompanyRole}
        />
      </div>
      <div className="p-8 w-full md:w-3/4 max-h-screen">
        {activeSection === "company-profile" && (
          <CompanyProfile company={company} isPublic={false} />
        )}
        {activeSection === "members" && (
          <Members company={company} userCompanyRole={userCompanyRole} />
        )}
        {activeSection === "settings" && (
          <Settings company={company} userCompanyRole={userCompanyRole} />
        )}
        {activeSection === "work-plan-templates" && (
          <WorkPlanTemplateAdmin company={company} />
        )}
        {activeSection === "inventory" && <AdminBikeList company={company} />}
      </div>
    </div>
  );
};

export default CompanyDashboard;
