import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const SingleBikeModel = () => {
  const { modelId } = useParams();
  const [bikeModel, setBikeModel] = useState(null);
  const [allPartTypes, setAllPartTypes] = useState([]); // State to store all part types
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBikeModel = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bike-model/${modelId}`
        );
        setBikeModel(response.data.bikeModel);
        // Assume allPartTypes are part of the response
        setAllPartTypes(response.data.allPartTypes);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (modelId) {
      fetchBikeModel();
    }
  }, [modelId]);

  const handleChangePartOnModel = (partId) => {
    console.log("Change part on model with ID:", partId);
    // Implement change part on model functionality here
  };

  const handleUpdatePartSpecs = (partId) => {
    console.log("Update part specs with ID:", partId);
    // Implement update part specs functionality here
  };

  const renderSpecs = (specs) => {
    return Object.entries(specs).map(([key, value]) =>
      typeof value === "object" ? (
        <li key={key}>
          {key}:{" "}
          {Object.entries(value).map(
            ([innerKey, innerValue]) => `${innerKey}: ${innerValue}, `
          )}
        </li>
      ) : (
        <li key={key}>
          {key}: {value}
        </li>
      )
    );
  };

  const renderMissingParts = () => {
    const existingPartTypeNames = bikeModel.default_parts.map(
      (part) => part.part.partType?.name
    );

    return allPartTypes
      .filter((partType) => !existingPartTypeNames.includes(partType.name))
      .map((missingPartType) => (
        <div key={missingPartType.id} className="missing-part-detail">
          <h3>{missingPartType.name}</h3>
          <button className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-lg">
            Add {missingPartType.name}
          </button>
        </div>
      ));
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!bikeModel) return <div>No bike model found.</div>;

  return (
    <div className="bg-gradient-to-br from-bikewise-green to-bikewise-green min-h-[100vh]">
      <div
        className="min-h-screen min-w-screen bg-cover p-8 bg-center"
        style={{ backgroundImage: `url("/images/backgroundPattern.svg")` }}
      >
        <div className="flex flex-col md:flex-row mx-auto rounded-xl bg-white p-8 gap-16">
          <div className="bike-model-details w-full">
            {bikeModel.status}
            <h1>
              {bikeModel.manufacturer?.name} - {bikeModel.name}
            </h1>
            <div className="flex flex-col gap-4">
              {bikeModel.default_parts.map((part) => (
                <div
                  key={part.id}
                  className="part-detail grid grid-cols-3 gap-4 border-b border-gray-300 pb-4"
                >
                  <div className="flex-grow">
                    <h3>{part.part.partType?.name}</h3>
                    <p>{part.part?.manufacturer?.name}</p>
                    <p>{part.part?.model}</p>
                  </div>
                  <ul className="flex-grow">{renderSpecs(part.specs)}</ul>
                  <div className="flex flex-col gap-2 items-start">
                    {part.part && (
                      <>
                        <button
                          className="bg-bikewise-yellow text-black font-bold py-4 px-10 rounded-xl shadow-lg transform transition hover:scale-105"
                          onClick={() => handleChangePartOnModel(part.part.id)}
                        >
                          Change Part on Model
                        </button>
                        <button
                          className="bg-bikewise-yellow text-black font-bold py-4 px-10 rounded-xl shadow-lg transform transition hover:scale-105"
                          onClick={() => handleUpdatePartSpecs(part.part.id)}
                        >
                          Update Part Specs
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <div className="missing-parts">{renderMissingParts()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBikeModel;
