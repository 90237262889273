import React from "react";
import Dino from "../components/404/Dino";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col gap-3 items-center justify-center w-screen h-[60vh] p-4">
      <h1 className="text-3xl font-bold">404 - Not Found</h1>
      <Dino />
    </div>
  );
};

export default NotFoundPage;
