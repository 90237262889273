import React from "react";

const Temp = () => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <img
        className="w-full"
        src="/images/logos/fullWhite.svg"
        alt="BikeWise"
      />
    </div>
  );
};

export default Temp;
