import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import CustomSelect from "./customSelect";

const IdentificationForm = ({ onComplete, settings }) => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [otherValue, setOtherValue] = useState("");
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchManufacturers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/manufacturers`,
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const manufacturers = response.data.map(
        (manufacturer) => manufacturer.name
      );
      setManufacturers(manufacturers);
    } catch (error) {
      console.error("Error fetching manufacturers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchModels = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/bike/find/models`,
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
          params: {
            year: answers.year,
            manufacturer: answers.manufacturer,
            type: answers.type,
            electric: answers.electric === "Yes",
          },
        }
      );
      setModels(response.data);
      if (response.data.length === 0) {
        moveToNextQuestion();
      }
    } catch (error) {
      console.error("Error fetching models:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVariants = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/bike/find/model/variants`,
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
          params: {
            year: answers.year,
            manufacturer: answers.manufacturer,
            model: answers.model,
            electric: answers.electric === "Yes",
          },
        }
      );
      setVariants(response.data);
      if (response.data.length === 0) {
        moveToNextQuestion();
      }
    } catch (error) {
      console.error("Error fetching variants:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchManufacturers();
  }, []);

  useEffect(() => {
    if (
      answers.manufacturer &&
      answers.manufacturer !== "Other" &&
      answers.year &&
      answers.type &&
      answers.electric !== undefined
    ) {
      fetchModels();
    }
  }, [answers.manufacturer, answers.year, answers.type, answers.electric]);

  useEffect(() => {
    if (
      answers.manufacturer &&
      answers.manufacturer !== "Other" &&
      answers.model &&
      answers.year &&
      answers.type &&
      answers.electric !== undefined
    ) {
      fetchVariants();
    }
  }, [
    answers.manufacturer,
    answers.model,
    answers.year,
    answers.type,
    answers.electric,
  ]);

  const questions = [
    {
      question: "What type of bike do you have and is it electric?",
      options: [
        "Road",
        "Mountain",
        "Hybrid",
        "Cyclocross",
        "Gravel",
        "BMX",
        "Track",
        "Folding",
        "Cargo",
        "Urban/City",
        "Kids",
        "Other",
      ],
      key: "type",
      electricKey: "electric",
    },
    {
      question: "What year is your bike?",
      options: Array.from({ length: 74 }, (_, i) => (2024 - i).toString()),
      key: "year",
    },
    {
      question: "What's the manufacturer of your bike?",
      options: [...manufacturers, "Other"],
      key: "manufacturer",
    },
    {
      question: "What's the model of your bike?",
      options: [...models, "Other"],
      key: "model",
    },
    {
      question: "What's the model variant?",
      options: [...variants, "Other"],
      key: "model_version",
    },
    {
      question: "What color is your bike? (optional)",
      key: "color",
      freeform: true,
    },
  ];

  const handleAnswer = (answer, key) => {
    if (answer === "Other") {
      setAnswers({ ...answers, [key]: "Other" });
      setOtherValue("");
    } else {
      setAnswers({ ...answers, [key]: answer });
      if (key !== "type" || (answers.type && answers.electric !== undefined)) {
        moveToNextQuestion();
      }
    }
  };

  const handleOtherSubmit = (key) => {
    setAnswers({ ...answers, [key]: otherValue });
    setOtherValue("");
    moveToNextQuestion();
  };

  const [transitioning, setTransitioning] = useState(false);

  const moveToNextQuestion = () => {
    if (step < questions.length - 1) {
      setTransitioning(true);
      setTimeout(() => {
        setStep(step + 1);
        setTransitioning(false);
      }, 300); // Match this with the transition duration
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setStep(step - 1);
        setTransitioning(false);
      }, 300); // Match this with the transition duration
    }
  };

  const handleSubmit = () => {
    onComplete(answers);
  };

  const renderQuestion = (question) => {
    if (question.freeform) {
      return (
        <input
          type="text"
          value={answers[question.key] || ""}
          onChange={(e) =>
            setAnswers({ ...answers, [question.key]: e.target.value })
          }
          className="w-full p-2 border rounded"
          placeholder="Enter your answer"
        />
      );
    }

    if (question.key === "type") {
      return (
        <div className="flex gap-2 items-center w-full">
          <div className="w-full">
            <CustomSelect
              options={question.options}
              value={answers[question.key] || ""}
              onChange={(value) => handleAnswer(value, question.key)}
              settings={settings}
              disabled={isLoading}
            />
          </div>
          <div>
            <span className="text-sm">Is it electric?</span>
            <div className="flex space-x-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="electric"
                  value="Yes"
                  checked={answers.electric === "Yes"}
                  onChange={() => handleAnswer("Yes", "electric")}
                  className="mr-2"
                />
                Yes
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="electric"
                  value="No"
                  checked={answers.electric === "No"}
                  onChange={() => handleAnswer("No", "electric")}
                  className="mr-2"
                />
                No
              </label>
            </div>
          </div>
        </div>
      );
    }

    return (
      <CustomSelect
        options={question.options}
        value={answers[question.key] || ""}
        onChange={(value) => handleAnswer(value, question.key)}
        settings={settings}
        disabled={isLoading}
      />
    );
  };

  const currentQuestion = questions[step];

  const renderOtherInput = (key) => (
    <div className="flex items-center">
      <input
        type="text"
        value={otherValue}
        onChange={(e) => setOtherValue(e.target.value)}
        className="w-full p-2 border rounded-tl rounded-bl"
        placeholder={`Specify other ${key}`}
      />
      <button
        onClick={() => handleOtherSubmit(key)}
        className="p-2 rounded-tr rounded-br"
        style={{
          backgroundColor: settings.primaryColor,
          color: settings.buttonTextColor,
        }}
      >
        Submit
      </button>
    </div>
  );

  return (
    <div
      className="p-4 bg-white rounded-lg shadow-md relative"
      style={{
        backgroundColor: settings.backgroundColor,
        color: settings.textColor,
      }}
    >
      <div className={isLoading ? "opacity-50 pointer-events-none" : ""}>
        <div
          className={`transition-opacity duration-300 ease-in-out ${
            transitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-bold">{currentQuestion.question}</h2>
          </div>
          {answers[currentQuestion.key] === "Other"
            ? renderOtherInput(currentQuestion.key)
            : renderQuestion(currentQuestion)}
        </div>
        <div className="flex justify-between items-center mt-2">
          {step > 0 && (
            <button onClick={handleBack} className="text-sm text-gray-500">
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
          )}
          <div className="text-sm text-gray-500">
            Question {step + 1} of {questions.length}
          </div>
        </div>

        {step === questions.length - 1 && (
          <button
            onClick={handleSubmit}
            className="mt-4 w-full p-2 rounded"
            style={{
              backgroundColor: settings.primaryColor,
              color: settings.buttonTextColor,
            }}
          >
            Submit
          </button>
        )}
      </div>
      {isLoading && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      )}
    </div>
  );
};

export default IdentificationForm;
