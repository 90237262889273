import React, { useState } from "react";
import { useStore } from "../../store";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faWrench } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function CompanyProfile({ company, isPublic }) {
  const navigate = useNavigate();
  const userInfo = useStore((state) => state.userInfo);
  const token = useStore((state) => state.token);
  const setToast = useStore((state) => state.setToast);
  const setUserInfo = useStore((state) => state.setUserInfo);

  // State for each field
  const [name, setName] = useState(company.name);
  const [email, setEmail] = useState(company.email);
  const [phone, setPhone] = useState(company.phone);
  const [website, setWebsite] = useState(company.website);
  const [address, setAddress] = useState(company.address);

  // Update handler (to be implemented)
  const handleUpdate = async () => {
    // Implement the logic to update the company profile
  };

  return (
    <div className="flex flex-col text-black bg-white h-full rounded-xl p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-6 items-center">
          <button onClick={() => navigate("/profile?screen=bikes")}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <h1 className="text-4xl font-extrabold">Company Profile</h1>
        </div>

        {!isPublic && (
          <div className="flex items-center justify-between">
            <button
              className="bg-bikewise-yellow text-black font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline hover:scale-105 transition duration-150 ease-in-out"
              type="button"
              onClick={handleUpdate}
            >
              <FontAwesomeIcon icon={faWrench} className="mr-4" />
              Update Profile
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-4 p-8">
        {/* Render the company profile form */}
        {isPublic ? (
          <div className="flex flex-col gap-4">
            <div>
              {/* Name */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">
                  Company Name
                </label>
                <p className="text-gray-700">{company.name}</p>
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Email</label>
                <p className="text-gray-700">{company.email}</p>
              </div>

              {/* Phone */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Phone</label>
                <p className="text-gray-700">{company.phone}</p>
              </div>

              {/* Website */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Website</label>
                <p className="text-gray-700">{company.website}</p>
              </div>

              {/* Address */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Address</label>
                <p className="text-gray-700">{company.address}</p>
              </div>
              <div>
                <label className="block text-sm font-bold mb-2">Stores</label>
                {company.stores.map((store) => (
                  <p key={store.id} className="text-gray-700">
                    {store.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <form className="flex flex-col gap-4">
            <div>
              {/* Name */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="name">
                  Company Name
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Phone */}
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="phone">
                  Phone
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="phone"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              {/* Website */}
              <div className="mb-4">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="website"
                >
                  Website
                </label>
                <input
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="website"
                  type="url"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>

              {/* Address */}
              <div className="mb-4">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="address"
                >
                  Address
                </label>
                <textarea
                  className="border border-gray-300 rounded-lg text-gray-700 p-2 w-full text-sm focus:ring-bikewise-green focus:border-bikewise-green"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-bold mb-2">Stores</label>
                {company.stores.map((store) => (
                  <p key={store.id} className="text-gray-700">
                    {store.name}
                  </p>
                ))}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
