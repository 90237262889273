import { create } from "zustand";
import Cookies from "js-cookie";

const safeParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

const sendMessageToContentScript = (action, payload) => {
  return new Promise((resolve, reject) => {
    window.postMessage({ type: "FROM_REACT", action, ...payload }, "*");

    const listener = (event) => {
      if (
        event.source === window &&
        event.data &&
        event.data.type === "FROM_EXTENSION"
      ) {
        window.removeEventListener("message", listener);
        clearTimeout(timeoutId);
        resolve(event.data);
      }
    };

    const timeoutId = setTimeout(() => {
      window.removeEventListener("message", listener);
      resolve(null);
    }, 300); // Adjust timeout duration as needed

    window.addEventListener("message", listener);
  });
};

const setStorage = async (key, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
  await sendMessageToContentScript("setStoreData", { key, data });
};

const getStorage = async (key) => {
  const localData = safeParse(localStorage.getItem(key));
  if (localData === null) {
    const response = await sendMessageToContentScript("getStoreData", { key });
    return response && response.data ? safeParse(response.data) : localData;
  } else {
    return localData;
  }
};

const removeStorage = async (key) => {
  localStorage.removeItem(key);
  await sendMessageToContentScript("removeStoreData", { key });
};

export const useStore = create((set) => ({
  isAuthenticated: false,
  token: null,
  userInfo: null,
  bikes: null,
  selectedBike: null,
  isOpen: false,
  isLoading: false,
  toast: null,
  tempBike: null,
  chromeExtensionInstalled: true,

  setAuth: async (token, userInfo) => {
    const authUpdated = Date.now();
    userInfo.authUpdated = authUpdated;
    await setStorage("bw-token", token);
    await setStorage("bw-userInfo", userInfo);
    Cookies.set("bw-token", token);
    Cookies.set("bw-userInfo", JSON.stringify(userInfo));
    set({ isAuthenticated: true, token, userInfo });
  },

  setChromeExtensionInstalled: async (chromeExtensionInstalled) => {
    set({ chromeExtensionInstalled });
  },

  clearAuth: async () => {
    await removeStorage("bw-token");
    await removeStorage("bw-userInfo");
    Cookies.remove("bw-token");
    Cookies.remove("bw-userInfo");
    set({
      isAuthenticated: false,
      token: null,
      userInfo: null,
      bikes: null,
      selectedBike: null,
    });
  },

  setUserInfo: async (userInfo) => {
    const authUpdated = Date.now();
    userInfo.authUpdated = authUpdated;
    await setStorage("bw-userInfo", userInfo);
    Cookies.set("bw-userInfo", JSON.stringify(userInfo));
    set({ userInfo });
  },

  initializeAuth: async () => {
    const token = await getStorage("bw-token");
    const userInfo = await getStorage("bw-userInfo");

    if (token && userInfo) {
      const authUpdated = Date.now();
      userInfo.authUpdated = authUpdated;
      set({ isAuthenticated: true, token, userInfo });
    }
  },

  initializeIsOpen: async () => {
    const isOpen = await getStorage("isOpen");
    if (isOpen) {
      set({ isOpen });
    }
  },

  setIsOpen: async (isOpen) => {
    await setStorage("isOpen", isOpen);
    set({ isOpen });
  },

  resetStore: async () => {
    await removeStorage("bikes");
    await removeStorage("selectedBike");
    await removeStorage("isOpen");
    set({ bikes: null, selectedBike: null, isOpen: false });
  },

  setIsLoading: (loading) => set({ isLoading: loading }),

  setToast: (message) => set({ toast: { message } }),

  clearToast: () => set({ toast: null }),

  setTempBike: async (bikeId) => {
    await setStorage("tempBike", bikeId);
    set({ tempBike: bikeId });
  },

  clearTempBike: async () => {
    await removeStorage("tempBike");
    set({ tempBike: null });
  },

  initializeTempBike: async () => {
    const bikeId = await getStorage("tempBike");
    if (bikeId) {
      set({ tempBike: bikeId });
    }
  },
}));
