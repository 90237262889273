import React, { useState, useEffect } from "react";
import axios from "axios";

const ThreadMessages = ({ thread, token, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMessages();
  }, [thread.id]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/threads/${thread.thread_id}/messages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching thread messages:", error);
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // Or use any other date formatting method you prefer
  };

  return (
    <div className="flex flex-col h-full overflow-y-scroll">
      <button
        onClick={onBack}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Back to Threads
      </button>
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">{thread.title}</h2>
        <p>
          <strong>Thread ID:</strong> {thread.thread_id}
        </p>
        <p>
          <strong>User ID:</strong> {thread.user_id}
        </p>
        <p>
          <strong>Active:</strong> {thread.is_active ? "Yes" : "No"}
        </p>
        <p>
          <strong>Total Messages:</strong> {thread.totalMessages}
        </p>
        <p>
          <strong>Last Assistant ID:</strong>{" "}
          {thread.last_assistant_id || "N/A"}
        </p>
        <p>
          <strong>Variable Costs:</strong>{" "}
          {JSON.stringify(thread.variable_costs)}
        </p>
        <p>
          <strong>Context:</strong> {JSON.stringify(thread.context)}
        </p>
        <p>
          <strong>Last Message Timestamp:</strong>{" "}
          {thread.last_message_timestamp || "N/A"}
        </p>
        <p>
          <strong>Created At:</strong>{" "}
          {new Date(thread.created_at).toLocaleString()}
        </p>
        <p>
          <strong>Updated At:</strong>{" "}
          {new Date(thread.updated_at).toLocaleString()}
        </p>
      </div>
      <h3 className="text-xl font-semibold mb-4">Messages</h3>
      {loading ? (
        <p>Loading messages...</p>
      ) : (
        <div className="space-y-4">
          {messages.length > 0 ? (
            messages.map((message) => (
              <div key={message.id} className="border p-4 rounded">
                <p className="font-semibold">{message.role}</p>
                <p className="text-sm text-gray-500">
                  {formatTimestamp(message.created_at)}
                </p>
                <p className="mt-2">{message.content}</p>
              </div>
            ))
          ) : (
            <p>No messages in this thread.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ThreadMessages;
