import React, { useEffect, useState } from "react";

import LoginModal from "../components/auth/loginModal";
import { useStore } from "../store";

import { useNavigate } from "react-router-dom";
import Temp from "../components/home/temp";

const Homepage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const userInfo = useStore((state) => state.userInfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      setIsLoginModalOpen(true);
    } else {
      setIsLoginModalOpen(false);
      navigate("/profile?screen=bikes");
    }
  }, [userInfo]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-bikewise-green to-bikewise-green">
      <div
        className="min-h-screen min-w-screen bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: `url("/images/backgroundPattern.svg")` }}
      >
        <Temp />
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default Homepage;
