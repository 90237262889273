import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import QRCode from "qrcode.react";

const BikeDetails = ({ bike }) => {
  const columns = [
    { field: "updated_at", headerName: "Date", width: 120 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const serviceRecords = bike.service_records;

  console.log(bike);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "4px",
          bgcolor: "white",
        }}
      >
        <img
          src={bike.color_scheme.image_url}
          style={{ width: "100%", height: "auto" }}
        />
        <Typography variant="h6" component="div" gutterBottom>
          {
            bike.bike_model_variant_size.bike_model_variant.bike_model
              .manufacturer.name
          }{" "}
          {bike.bike_model_variant_size.bike_model_variant.bike_model.name}{" "}
          {bike.bike_model_variant_size.bike_model_variant.name}
          <br />
        </Typography>

        <div className="w-full flex flex-col items-center justify-center gap-2 mb-2 mt-2">
          <p className="text-sm">{bike.id}</p>
          <QRCode
            value={`https://wa.me/33783167075?text=${encodeURIComponent(
              `Bike ID: ${bike.id}`
            )}`}
            size={125}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          {serviceRecords && serviceRecords.length === 0 ? (
            <Typography variant="body2" component="div">
              No service records found.
            </Typography>
          ) : (
            <div style={{ height: 300, width: "100%", marginTop: "16px" }}>
              <DataGrid rows={serviceRecords} columns={columns} pageSize={5} />
            </div>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default BikeDetails;
