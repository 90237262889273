import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCZ8fM80VNaIO70CSVbq4Cxz5ZKf_zKwvc",
  authDomain: "bikewise-analytics.firebaseapp.com",
  projectId: "bikewise-analytics",
  storageBucket: "bikewise-analytics.appspot.com",
  messagingSenderId: "298398765333",
  appId: "1:298398765333:web:44f4f5b6ad5fa66ccb191c",
  measurementId: "G-4KM5Q1FJVG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, logEvent, setUserId };
