import React from "react";
import { Modal, Paper, Typography, Box, CircularProgress } from "@mui/material";

export const JobDetailsModal = ({ open, handleClose, jobDetails, loading }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="job-details-modal"
      aria-describedby="job-details-description"
    >
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Job Details
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : jobDetails ? (
          <Box sx={{ mt: 2 }}>
            <img
              src={jobDetails.original_image_url}
              alt="Job Image"
              className="w-600 h-auto"
            />
            {jobDetails.bike_models[0].year}{" "}
            {jobDetails.bike_models[0].manufacturer}{" "}
            {jobDetails.bike_models[0].model}{" "}
            {jobDetails.bike_models[0].model_version}
          </Box>
        ) : (
          <Typography sx={{ mt: 2 }}>No job details available</Typography>
        )}
      </Paper>
    </Modal>
  );
};
