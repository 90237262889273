import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { getEndpointLabel } from "./utils";
import { fetchJobDetails } from "./api";

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "endpoint",
    headerName: "Endpoint",
    width: 200,
    valueGetter: (params) => getEndpointLabel(params.value),
  },
  { field: "origin", headerName: "Origin", width: 150 },
  {
    field: "total_request_time",
    headerName: "Total Request Time",
    width: 100,
    type: "number",
  },
  { field: "job_status", headerName: "Job Status", width: 200 },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 200,
    type: "dateTime",
    valueGetter: (params) => {
      return params.value ? new Date(params.value) : null;
    },
  },
  {
    field: "company",
    headerName: "Company",
    width: 150,
    valueGetter: (params) => params.row.api_key?.company?.name || "N/A",
  },
  {
    field: "job_id",
    headerName: "Job ID",
    width: 75,
    valueGetter: (params) => params.row.job_id || "No Job",
  },
  {
    field: "poll_attempts",
    headerName: "Poll Attempts",
    width: 75,
    valueGetter: (params) => params.row.poll_attempts || 0,
  },
  {
    field: "successful_redirect",
    headerName: "Successful Redirect",
    width: 100,
    valueGetter: (params) => params.row.successful_redirect,
  },
  {
    field: "redirect_url",
    headerName: "Redirect URL",
    width: 375,
    valueGetter: (params) => params.row.redirect_url || "N/A",
  },
];

export const DataGridComponent = ({
  filteredRequests,
  loading,
  setJobModalOpen,
  setSelectedJobDetails,
  setJobLoading,
  token,
}) => {
  const handleRowClick = async (params) => {
    setJobLoading(true);
    setJobModalOpen(true);
    try {
      const jobId = params.row.job_id;
      const endpoint = params.row.endpoint;
      if (jobId) {
        const jobDetails = await fetchJobDetails(token, jobId, endpoint);
        setSelectedJobDetails(jobDetails);
      } else {
        setSelectedJobDetails(null);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
      setSelectedJobDetails(null);
    } finally {
      setJobLoading(false);
    }
  };

  return (
    <Box sx={{ height: 750, mt: 2 }}>
      <DataGrid
        sx={{ backgroundColor: "#fff" }}
        rows={filteredRequests}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        checkboxSelection
        disableSelectionOnClick
        loading={loading}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};
