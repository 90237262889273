import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBuilding,
  faUsers,
  faRightFromBracket,
  faGears,
  faBarChart,
  faNoteSticky,
  faSheetPlastic,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar({ activeSection, setActiveSection, userCompanyRole }) {
  const userInfo = useStore((state) => state.userInfo);
  const { clearAuth } = useStore();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-auto md:h-screen flex flex-col justify-between items-center pt-8 pb-8 w-full gap-4 md:gap-0">
      <Link to={userInfo ? "/profile?screen=bikes" : "/"} className="w-full">
        <img src="/images/logos/fullAPI.svg" alt="BikeWise" className="w-1/2" />
      </Link>
      {mobile && (
        <div className="w-full">
          <button
            className="w-full border-2 border-bikewise-red text-bikewise-red text-center font-bold rounded-lg py-2 hover:scale-105 transition duration-150 ease-in-out"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faBars} /> Menu
          </button>
        </div>
      )}
      <div
        className={`flex flex-col justify-between h-full w-full ${
          mobile && !isSidebarOpen ? "hidden" : "mt-2 rounded-xl"
        }`}
      >
        {!mobile && (
          <div className="h-full flex flex-col justify-end mb-8">
            <div className="flex flex-col gap-2">
              <nav className="flex flex-col gap-2">
                <ul className="flex flex-col gap-2">
                  {(userCompanyRole === "owner" ||
                    userCompanyRole === "admin") && (
                    <li
                      className={`py-2 px-4 rounded hover:bg-bikewise-azure cursor-pointer ${
                        activeSection === "work-plan-templates"
                          ? "bg-bikewise-azure"
                          : ""
                      }`}
                      onClick={() => setActiveSection("work-plan-templates")}
                    >
                      <FontAwesomeIcon icon={faSheetPlastic} className="mr-2" />
                      Work Plan Templates
                    </li>
                  )}
                  {(userCompanyRole === "owner" ||
                    userCompanyRole === "admin") && (
                    <li
                      className={`py-2 px-4 rounded hover:bg-bikewise-azure cursor-pointer ${
                        activeSection === "inventory" ? "bg-bikewise-azure" : ""
                      }`}
                      onClick={() => setActiveSection("inventory")}
                    >
                      <FontAwesomeIcon icon={faBarChart} className="mr-2" />
                      Inventory
                    </li>
                  )}
                  <hr className="border-gray-300" />
                  <li
                    className={`py-2 px-4 rounded hover:bg-bikewise-azure cursor-pointer ${
                      activeSection === "company-profile"
                        ? "bg-bikewise-azure"
                        : ""
                    }`}
                    onClick={() => setActiveSection("company-profile")}
                  >
                    <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                    Company Profile
                  </li>
                  {(userCompanyRole === "owner" ||
                    userCompanyRole === "manager" ||
                    userCompanyRole === "admin") && (
                    <li
                      className={`py-2 px-4 rounded hover:bg-bikewise-azure cursor-pointer ${
                        activeSection === "members" ? "bg-bikewise-azure" : ""
                      }`}
                      onClick={() => setActiveSection("members")}
                    >
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      Members
                    </li>
                  )}
                  {(userCompanyRole === "owner" ||
                    userCompanyRole === "admin") && (
                    <li
                      className={`py-2 px-4 rounded hover:bg-bikewise-azure cursor-pointer ${
                        activeSection === "settings" ? "bg-bikewise-azure" : ""
                      }`}
                      onClick={() => setActiveSection("settings")}
                    >
                      <FontAwesomeIcon icon={faGears} className="mr-2" />
                      Settings
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        )}

        {userInfo && (
          <div className="flex gap-4 items-center w-full mt-auto">
            <Link to="/profile?screen=bikes">
              <div className="flex items-center gap-4">
                {userInfo && userInfo.profile_image_url ? (
                  <img
                    className="h-20 w-20 rounded-full mb-2"
                    src={userInfo.profile_image_url}
                    alt=""
                  />
                ) : (
                  <div className="h-20 w-20 rounded-full bg-gray-500 text-white flex items-center justify-center font-bold">
                    {userInfo?.name ? userInfo.name[0].toUpperCase() : "P"}
                  </div>
                )}
              </div>
            </Link>
            <div className="flex flex-col w-full gap-2">
              <div>
                <h2 className="text-lg font-semibold">{userInfo.name}</h2>
                <p className="text-sm">{userInfo.email}</p>
              </div>
              <div className="flex justify-between w-full">
                <Link className="" to="/profile?screen=settings">
                  <FontAwesomeIcon icon={faGears} /> Settings
                </Link>
                <Link className="" to="/" onClick={() => clearAuth()}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> Sign Out
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
