import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faStrava } from "@fortawesome/free-brands-svg-icons";

const LoginModal = ({ isOpen, onClose, createAccount }) => {
  const { setAuth } = useStore();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [errors, setErrors] = useState({});

  const setToast = useStore((state) => state.setToast);

  useEffect(() => {
    setIsCreatingAccount(createAccount);
  }, [createAccount]);

  const navigate = useNavigate();

  const modalRef = useRef();

  const validateForm = () => {
    const newErrors = {};

    if (isCreatingAccount) {
      if (!name.trim()) newErrors.name = "Name is required";
      if (!email.trim()) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(email))
        newErrors.email = "Email is invalid";
      if (!password) newErrors.password = "Password is required";
      if (!confirmPassword)
        newErrors.confirmPassword = "Please confirm your password";
      else if (password !== confirmPassword)
        newErrors.confirmPassword = "Passwords do not match";
    } else {
      if (!email.trim()) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(email))
        newErrors.email = "Email is invalid";
      if (!password) newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/signin",
        {
          name,
          email,
          password,
        }
      );
      const token = response.data;

      const user = await axios.post(
        process.env.REACT_APP_API_URL + "/user/email",
        {
          email: email,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const userInfo = user.data;

      setAuth(token, userInfo);
      onClose();
      navigate("/profile?screen=bikes");
      setToast({
        type: "success",
        message: "Login successful!",
      });
    } catch (error) {
      console.error("Login failed:", error);
      setToast({
        type: "error",
        message: "Login failed - invalid password!",
      });
    }
  };

  const handleOAuthLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
  };

  return (
    <div
      className={`${
        isOpen ? "fixed" : "hidden"
      } inset-0 flex items-center justify-center z-[100000] transition-all duration-500`}
    >
      <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
      <div
        ref={modalRef}
        className={`bg-white p-8 rounded shadow-lg w-96 transition-transform transform duration-500 ease-in-out ${
          isOpen ? "scale-100" : "scale-90"
        }`}
      >
        <h2 className="text-2xl mb-4">
          {isCreatingAccount ? "Create Account" : "Login"}
        </h2>

        <form onSubmit={handleSubmit}>
          {isCreatingAccount && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`w-full p-2 border rounded ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name}</p>
              )}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full p-2 border rounded ${
                errors.email ? "border-red-500" : ""
              }`}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full p-2 border rounded ${
                errors.password ? "border-red-500" : ""
              }`}
            />
            {errors.password && (
              <p className="text-red-500 text-xs mt-1">{errors.password}</p>
            )}
          </div>
          {isCreatingAccount && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`w-full p-2 border rounded ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
          )}
          <button
            type="submit"
            className="w-full p-2 bg-bikewise-yellow text-black rounded-lg font-bold hover:scale-105"
          >
            {isCreatingAccount ? "Create Account" : "Login"}
          </button>
          {!isCreatingAccount ? (
            <>
              <div className="flex justify-center mt-2">
                <span className="text-gray-500 mx-2 mb-2">
                  Don't have an account?
                </span>
              </div>
              <button
                onClick={() => setIsCreatingAccount(true)}
                className="w-full p-2 bg-bikewise-green text-white rounded-lg transition-all duration-300 mb-2 font-bold hover:scale-105"
              >
                Create Account
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsCreatingAccount(false)}
              className="mt-4 text-gray-500 hover:underline"
            >
              Cancel
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
